import { ACCESS_LEVEL, USER_AUTH_STATUS, USER_INFO } from '../constant';
import { auth } from '../../utils/config/authConfig';
import { getUserPermissions } from '../../utils/authHelper';

export function userAuthStatus(authenticated) {
    return {
        type: USER_AUTH_STATUS,
        authenticated
    };
}

export function userInfo(user) {
    return {
        type: USER_INFO,
        payload: user
    };
}

export function userAccessLevels(accessLevels) {
    return {
        type: ACCESS_LEVEL,
        accessLevels
    };
}

/**
 * Fetch User info through identity manager
 *
 * @returns {Function}
 */
export function fetchUserInfo() {
    return dispatch => {
        auth.getUser()
            .then(user => {
                dispatch(userInfo(user));
                dispatch(userAccessLevels(getUserPermissions(user)));
            })
            .catch(error => {
                dispatch(userInfo({}));
                dispatch(userAccessLevels([]));
            });
    };
}