export const USER_AUTH_STATUS = 'USER_AUTHENTICATED';
export const USER_INFO = 'USER_INFO';
export const ACCESS_LEVEL = 'ACCESS_LEVEL';

export const FETCH_ACTIVE_ALERTS_PENDING = 'FETCH_ACTIVE_ALERTS_PENDING';
export const FETCH_ACTIVE_ALERTS_SUCCESS = 'FETCH_ACTIVE_ALERTS_SUCCESS';
export const FETCH_ACTIVE_ALERTS_ERROR = 'FETCH_ACTIVE_ALERTS_ERROR';
export const ACTIVE_ALERTS_STATE_SUCCESS = 'ACTIVE_ALERTS_STATE_SUCCESS';
export const ACTIVE_ALERTS_STATE_ERROR = 'ACTIVE_ALERTS_STATE_ERROR';
export const ACTIVE_ALERTS_ASSIGNEE_EXISTS = 'ACTIVE_ALERTS_ASSIGNEE_EXISTS';
export const ACTIVE_ALERTS_TABLE_SORT_COLUMN = 'ACTIVE_ALERTS_TABLE_SORT_COLUMN';
export const ACTIVE_ALERTS_TABLE_COLUMNS = 'ACTIVE_ALERTS_TABLE_COLUMNS';
export const ACTIVE_ALERTS_TABLE_FILTERS = 'ACTIVE_ALERTS_TABLE_FILTERS';
export const ACTIVE_ALERTS_SET_TABLE_DETAILS = 'ACTIVE_ALERTS_SET_TABLE_DETAILS';

export const FETCH_CLOSED_ALERTS_PENDING = 'FETCH_CLOSED_ALERTS_PENDING';
export const FETCH_CLOSED_ALERTS_SUCCESS = 'FETCH_CLOSED_ALERTS_SUCCESS';
export const FETCH_CLOSED_ALERTS_ERROR = 'FETCH_CLOSED_ALERTS_ERROR';
export const CLOSED_ALERTS_TABLE_SORT_COLUMN = 'CLOSED_ALERTS_TABLE_SORT_COLUMN';
export const CLOSED_ALERTS_TABLE_COLUMNS = 'CLOSED_ALERTS_TABLE_COLUMNS';
export const CLOSED_ALERTS_TABLE_FILTERS = 'CLOSED_ALERTS_TABLE_FILTERS';

export const TOGGLE_FULL_SCREEN = 'TOGGLE_FULL_SCREEN';

export const ADD_DOCUMENT_TO_INCIDENT_QUEUE = 'ADD_DOCUMENT_TO_INCIDENT_QUEUE';
export const CREATE_INCIDENT_PENDING = 'CREATE_INCIDENT_PENDING';
export const CREATE_INCIDENT_ERROR = 'CREATE_INCIDENT_ERROR';
export const REMOVE_DOCUMENT_FROM_INCIDENT_QUEUE = 'REMOVE_DOCUMENT_FROM_INCIDENT_QUEUE';

export const TOGGLE_ASSOCIATED_EVENTS_MODAL = 'TOGGLE_ASSOCIATED_EVENTS_MODAL';
export const SET_ASSOCIATED_EVENTS = 'SET_ASSOCIATED_EVENTS';

export const TIME_DETAILS = 'TIME_DETAILS';

export const AUTO_REFRESH_FLAG = 'AUTO_REFRESH_FLAG';

export const SET_DISPLAY_ALERT_DETAILS = 'SET_DISPLAY_ALERT_DETAILS';
export const TOGGLE_DISPLAY_ALERT_DETAILS_MODAL =
    'TOGGLE_DISPLAY_ALERT_DETAILS_MODAL';
export const FETCH_DISPLAY_ALERT_DETAILS_PENDING =
    'FETCH_DISPLAY_ALERT_DETAILS_PENDING';
export const FETCH_DISPLAY_ALERT_DETAILS_ERROR =
    'FETCH_DISPLAY_ALERT_DETAILS_ERROR';
