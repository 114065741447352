import {
    ADD_DOCUMENT_TO_INCIDENT_QUEUE,
    CREATE_INCIDENT_ERROR,
    CREATE_INCIDENT_PENDING,
    REMOVE_DOCUMENT_FROM_INCIDENT_QUEUE
} from '../../redux/constant';
import axios from 'axios';
import { fetchError, fetchPending, updateAlertIncidentDetails } from './activeAlertsAction';

/**
 * Put Create incident to queue
 *
 * @param documentId
 * @returns {{documentId: *, type: *}}
 */
export function putCreateIncidentToQueue(documentId) {
    return {
        type: ADD_DOCUMENT_TO_INCIDENT_QUEUE,
        documentId
    };
}

/**
 * Remove document id from queue
 *
 * @param documentId
 * @returns {{documentId: *, type: *}}
 */
export function removeDocumentIdFromCreateIncidentQueue(documentId) {
    return {
        type: REMOVE_DOCUMENT_FROM_INCIDENT_QUEUE,
        documentId
    };
}

/**
 * Handle create incident pending state
 *
 * @returns {{type: *}}
 */
export function createIncidentPending() {
    return {
        type: CREATE_INCIDENT_PENDING
    };
}

/**
 * Create incident error state
 *
 * @param error
 * @returns {{type: *}}
 */
export function createIncidentError(error) {
    return {
        type: CREATE_INCIDENT_ERROR,
        error
    };
}

/**
 * Create incident
 *
 * @param documentId
 * @returns {Function}
 */
export function createIncident(documentId) {
    return dispatch => {
        // make table loading progress
        dispatch(fetchPending());
        // create incident action into queue
        dispatch(putCreateIncidentToQueue(documentId));
        // make as pending create incident process
        dispatch(createIncidentPending());
        // make PUT request to incident endpoint
        axios.put('ac/incident', {
            'DocId': documentId
        })
            .then(({ data }) => {
                if (data.error) {
                    throw data.error;
                }
                // remove item from incident queue
                dispatch(removeDocumentIdFromCreateIncidentQueue(documentId));
                // update alert incident details
                dispatch(updateAlertIncidentDetails(documentId, data));
            })
            .catch(err => {
                dispatch(createIncidentError(err));
                dispatch(fetchError(err));
            });
    };
}