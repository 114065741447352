import { SET_ASSOCIATED_EVENTS, TOGGLE_ASSOCIATED_EVENTS_MODAL } from '../../redux/constant';

const initialState = {
    showModal: false,
    tableData: []
};

/**
 * Reducer for Associated Events Info
 *
 * @param state
 * @param action
 */
export function associatedEventsInfo(state = initialState, action) {
    switch (action.type) {
        case TOGGLE_ASSOCIATED_EVENTS_MODAL:
            return {
                ...state,
                showModal: !state.showModal,
            };
        case SET_ASSOCIATED_EVENTS:
            return {
                ...state,
                tableData: action.data
            };
        default:
            return state;
    }
}
