import { Auth } from '@okta/okta-react';
import { createBrowserHistory } from 'history';

/**
 * create browser history instance to support OKTA security component with router
 */
const history = createBrowserHistory();

/**
 * Create OKTA auth instance and share among react & non-react modules
 *
 * @type {Auth}
 */
const auth = new Auth({
    issuer: process.env.REACT_APP_AUTH_ISSUER_URL,
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID,
    pkce: true,
    redirectUri: `${ window.location.origin }/implicit/callback`,
    history
});

export { auth, history };