import {
    ACTIVE_ALERTS_ASSIGNEE_EXISTS,
    ACTIVE_ALERTS_SET_TABLE_DETAILS,
    ACTIVE_ALERTS_STATE_ERROR,
    ACTIVE_ALERTS_STATE_SUCCESS,
    ACTIVE_ALERTS_TABLE_COLUMNS,
    ACTIVE_ALERTS_TABLE_FILTERS,
    ACTIVE_ALERTS_TABLE_SORT_COLUMN,
    FETCH_ACTIVE_ALERTS_ERROR,
    FETCH_ACTIVE_ALERTS_PENDING,
    FETCH_ACTIVE_ALERTS_SUCCESS
} from '../../redux/constant';
import Constants from '../../utils/constants';

const initialState = {
    pending: false,
    tableData: [],
    error: null,
    stateChangeRes: null,
    stateChangeError: null,
    assigneeExists: false,
    tableSort: {
        // the default sort column
        column: 'timestamp',
        mode: Constants.COMMON.DESCENDING_FULL
    },
    tableFilterList: [],
    tableColumns: {
        'timestamp': true,
        'source': true,
        'message': true,
        'current_status': true,
        'severity': true,
        'elapsed_response_time': true,
        'last_status_timestamp': false,
        'elapsed_time_status': false,
        'incident': true,
        'incidentstatus': true,
        'agent': true,
        'actionType': true,
        'associatedEvents': true
    }
};

export function activeAlertsInfo(state = initialState, action) {
    switch (action.type) {
        case FETCH_ACTIVE_ALERTS_PENDING:
            return {
                ...state,
                pending: true
            };
        case FETCH_ACTIVE_ALERTS_SUCCESS:
            return {
                ...state,
                pending: false,
                tableData: action.payload
            };
        case FETCH_ACTIVE_ALERTS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            };
        case ACTIVE_ALERTS_SET_TABLE_DETAILS:
            return {
                ...state,
                pending: false,
                tableData: action.payload
            };
        case ACTIVE_ALERTS_STATE_SUCCESS:
            return {
                ...state,
                pending: false,
                stateChangeRes: action.payload
            };
        case ACTIVE_ALERTS_STATE_ERROR:
            return {
                ...state,
                pending: false,
                stateChangeError: action.error
            };
        case ACTIVE_ALERTS_ASSIGNEE_EXISTS:
            return {
                ...state,
                assigneeExists: !state.assigneeExists
            };
        case ACTIVE_ALERTS_TABLE_SORT_COLUMN:
            return {
                ...state,
                tableSort: action.payload
            };
        case ACTIVE_ALERTS_TABLE_COLUMNS:
            return {
                ...state,
                tableColumns: action.payload
            };
        case ACTIVE_ALERTS_TABLE_FILTERS:
            return {
                ...state,
                tableFilterList: action.payload
            };
        default:
            return state;
    }
}
