import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import SystemClock from '../systemClock/SystemClock';
import TimePickerDialog from '../timePickerDialog/timePickerDialog';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { updateTimeDetails } from '../../redux/actions/timeAction';
import { setAutoRefresh } from '../../redux/actions/autoRefreshAction';
import Snackbar from '@material-ui/core/Snackbar';
import { withAuth } from '@okta/okta-react';
import { fetchUserInfo, userAuthStatus } from '../../redux/actions/userAction';
import { isUndefined, isNull, toUpper } from 'lodash';
import initials from 'initials';
import { withRouter } from 'react-router-dom';
import './Header.scss';

const TealSwitch = withStyles({
    switchBase: {
        color: '#1aa6b1',
        '&$checked': {
            color: '#1aa6b1'
        },
        '&$checked + $track': {
            backgroundColor: '#1aa6b1'
        }
    },
    checked: {},
    track: {}
})(Switch);

class Header extends Component {
    constructor (props) {
        super(props);
        this.state = {
            openTimePicker: false,
            snackbarFlag: false,
            currentTab: 0
        };
    }

    componentDidMount() {
        let currentTab = 0;
        // Check Current route url
        switch (this.props.location.pathname) {
            case '/alertConsole':
                currentTab = 0;
                break;
            case '/closedAlerts':
                currentTab = 1;
                break;
            default:
                currentTab = 0;
        }
        this.setState({ currentTab });
        this.checkAuthentication();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.checkAuthentication();
    }

    /**
     * Check Authentication
     */
    async checkAuthentication() {
        // get `authenticated` status from OKTA-Client lib
        const authenticated = await this.props.auth.isAuthenticated();
        console.log('authenticated :', authenticated);
        if (authenticated !== this.props.authenticated) {
            // set authenticated status
            this.props.setUserAuthStatus(authenticated);
            // check auth status & fetch user details
            if (authenticated) {
                this.props.fetchUser();
            }
        }
    }

    handleCloseSnackbar = () => {
        this.setState({
            snackbarFlag: false
        });
    };

    handleChange = () => {
        const { autoRefresh } = this.props;
        this.props.setAutoRefresh();
        if (!autoRefresh) this.setState({ snackbarFlag: true });
    };

    openTimePickerDialog = () => {
        this.setState({ openTimePicker: true });
    };

    closeTimePickerDialog = () => {
        this.setState({ openTimePicker: false });
    };

    selectTimeRange = time => {
        this.setState({
            openTimePicker: false
        });
        console.log(time);
        this.props.updateTimeDetails(time);
    };

    /**
     * Get initials for given name
     */
    getInitials = (name) => {
        if (!isUndefined(name) && !isNull(name)) {
            return toUpper(initials(name));
        } else {
            return '-';
        }
    };

    render() {
        const { openTimePicker, snackbarFlag } = this.state;
        const { timeRangeLabel, autoRefresh, authenticated, userDetails } = this.props;
        const { name } = userDetails;
        // if no authentication - do not display header
        if (!authenticated) {
            return null;
        }
        return (
            <div className={ 'header' }>
                <div className={ 'left-con' }>
                    <img
                        className={ 'logo' }
                        src={ `${ process.env.PUBLIC_URL }/images/logo.gif` }
                        alt='jupiter logo'
                    />
                    <span className={ 'title' }>NSOC</span>
                </div>
                <div className={ 'center-con' }>
                    <div className={ 'section-one' }>
                        <Grid container>
                            <Grid item xs={ 4 } md={ 6 } sm={ 7 }>
                                <div className={ 'inner-one' }>
                                    <div className={ 'logo-con' }>
                                        <img
                                            className={ 'wiley-logo' }
                                            src={ `${ process.env.PUBLIC_URL }/images/wiley-logo.png` }
                                            alt='wiley logo'
                                        />
                                    </div>
                                    <div className={ 'system-clock' }>
                                        <SystemClock />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={ 8 } md={ 6 } sm={ 5 }>
                                <div className={ 'inner-two' }>
                                    <div className={ 'time-range-picker-con' }>
                                        <Button
                                            variant='outlined'
                                            className={ 'time-range-picker' }
                                            onClick={ this.openTimePickerDialog }
                                        >
                                            { timeRangeLabel }
                                        </Button>
                                    </div>
                                    <div className={ 'auto-refresh-con' }>
                                        <FormControlLabel
                                            className={ 'auto-refresh-label' }
                                            control={
                                                <TealSwitch
                                                    checked={ autoRefresh }
                                                    onChange={ this.handleChange }
                                                    value={ true }
                                                    size={ 'small' }
                                                />
                                            }
                                            label={ 'Auto Refresh' }
                                        />
                                    </div>
                                    <Snackbar
                                        anchorOrigin={ {
                                            vertical: 'top',
                                            horizontal: 'center'
                                        } }
                                        className={ 'auto-refresh-snackbar' }
                                        open={ snackbarFlag }
                                        autoHideDuration={ 2000 }
                                        onClose={ this.handleCloseSnackbar }
                                        message={
                                            <span id='message-id'>
                                                Auto refresh set to { parseInt(process.env.REACT_APP_AUTO_REFRESH_INTERVAL) / 1000 } seconds
                                            </span>
                                        }
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className={ 'section-two' }>
                        <Tabs className={ 'nav-panel' } value={ 0 }>
                            {/*<Tab className={ 'capitalize-fonts' } label={ 'Problem Summary' }/>
                            <Tab className={ 'capitalize-fonts' } label={ 'System Health' }/>*/}
                            <Tab
                                className={ 'capitalize-fonts' }
                                label={ 'Alert Console' }
                            />
                        </Tabs>
                    </div>
                </div>
                <div className={ 'right-con' }>
                    <Tooltip title={ !isUndefined(name) && !isNull(name) ? name : '' }>
                        <IconButton className={ 'avatar-button' }>
                            <Avatar className={ 'avatar-icon' }>{ this.getInitials(name) }</Avatar>
                        </IconButton>
                    </Tooltip>
                </div>
                <TimePickerDialog
                    currentTab={ this.state.currentTab }
                    open={ openTimePicker }
                    close={ this.closeTimePickerDialog }
                    onSelect={ this.selectTimeRange }
                />
            </div>
        );
    }
}

Header.propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    updateTimeDetails: PropTypes.func,
    timeRangeLabel: PropTypes.string,
    setAutoRefresh: PropTypes.func,
    autoRefresh: PropTypes.bool,
    authenticated: PropTypes.bool,
    auth: PropTypes.object,
    setUserAuthStatus: PropTypes.func,
    fetchUser: PropTypes.func,
    userDetails: PropTypes.object,
};

const mapStateToProps = state => ({
    timeRangeLabel: state.timeDetails.name,
    autoRefresh: state.autoRefreshInfo.status,
    authenticated: state.userInfo.authenticated,
    userDetails: state.userInfo.userDetails
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            updateTimeDetails: updateTimeDetails,
            setAutoRefresh: setAutoRefresh,
            setUserAuthStatus: userAuthStatus,
            fetchUser: fetchUserInfo,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(withAuth(withRouter(Header)));
