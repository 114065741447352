import React from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';

const theme = createMuiTheme({
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontWeight: 'normal',
                fontSize: '12px'
            }
        }
    }
});

const NsocToolTip = ({ value, children }) => {
    return (
        <div>
            <MuiThemeProvider theme={ theme }>
                <Tooltip title={ value }>
                    {children}
                </Tooltip>
            </MuiThemeProvider>
        </div>
    );
};

NsocToolTip.propTypes = {
    value: PropTypes.string,
    children: PropTypes.node.isRequired
};

export default NsocToolTip;