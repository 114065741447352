import React from 'react';
import Constants from './constants';
import {
    endsWith,
    find,
    groupBy,
    isArray,
    isEmpty,
    isEqual,
    isNull,
    isUndefined,
    keysIn,
    lowerCase,
    startsWith,
    upperFirst
} from 'lodash';
import store from '../store';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import CheckCircle from '@material-ui/icons/CheckCircle';
import ReportProblem from '@material-ui/icons/ReportProblem';
import Button from '@material-ui/core/Button';
import { createIncident } from '../redux/actions/incidentActions';
import { setAssociatedEvents, toggleAssociatedEventsModal } from '../redux/actions/associatedEventsAction';
import { checkUserHasPermission, getUsername } from './authHelper';
import { updateColumnOptions } from '../containers/activeAlerts/ColumnConfigs';

/**
 * Handler open service now link
 *
 * @param event
 * @param serviceNo
 */
export const openServiceNow = (event, serviceNo) => {
    // prevent default
    event.preventDefault();
    // replace the service id
    const serviceNowUrl = `${ process.env.REACT_APP_SERVICE_NOW_LINK_URL }`.replace('{service_id}', serviceNo);
    window.open(serviceNowUrl, '_blank');
};

/**
 * Custom Filter Options Render method
 *
 * @param filterName
 * @param filterKey
 * @param dataSource
 */
export const customFilterDisplayOptions = (filterName, filterKey, dataSource) => {
    let emptyValue = '';
    // determine empty value
    switch (filterKey) {
        case 'agent':
            emptyValue = upperFirst(Constants.COMMON.UNASSIGNED);
            break;
        case 'source':
            emptyValue = upperFirst(Constants.COMMON.EMAIL);
            break;
        default:
            emptyValue = upperFirst(Constants.COMMON.BLANK);
    }
    return (filterList, onChange, index, column) => {
        const state = store.getState();
        const optionValues = keysIn(groupBy(state[ dataSource ].tableData, filterKey));
        return (
            <FormControl>
                <InputLabel htmlFor="select-multiple-chip">{ filterName }</InputLabel>
                <Select
                    multiple
                    value={ filterList[ index ] }
                    renderValue={ selected => {
                        const renderItems = selected.map(item => {
                            if (emptyValue === upperFirst(Constants.COMMON.EMAIL)) {
                                return item;
                            }
                            return isEmpty(item) ? emptyValue : item;
                        });
                        return renderItems.join(', ');
                    } }
                    onChange={ (event) => {
                        filterList[ index ] = event.target.value;
                        onChange(filterList[ index ], index, column);
                    } }
                >
                    { optionValues.map(item => (
                        <MenuItem key={ item } value={ item }>
                            <Checkbox color="primary" checked={ filterList[ index ].indexOf(item) > -1 } />
                            { (emptyValue === upperFirst(Constants.COMMON.EMAIL)) ? <ListItemText primary={ item } />
                                : <ListItemText primary={ isEmpty(item) ? emptyValue : item } /> }
                        </MenuItem>
                    )) }
                </Select>
            </FormControl>
        );
    };
};

/**
 * Alert severity icon
 *
 * @param status
 * @returns {*}
 */
export const getAlertSeverityIcon = (status) => {
    switch (status) {
        case Constants.ALERT.SEVERITY.CRITICAL:
            return <ReportProblem className={ 'alert-severity-warning-icon' } />;
        default:
            return '';
    }
};

/**
 * Get Alert source value display customized
 *
 * @param value
 * @param tableMeta
 * @returns {string}
 */
export const getAlertSourceDisplayValue = (value, tableMeta, index) => {
    if (tableMeta && isArray(tableMeta.rowData) && tableMeta.rowData.length > 0) {
        const sourceAlias = tableMeta.rowData[ index ];
        if (sourceAlias) return sourceAlias;
        return value;
    } else {
        return '-';
    }
};

/**
 * Get action types
 *
 * @param value
 * @param selfHealedFlag
 */
export const getActionType = (value, selfHealedFlag = false) => {
    switch (value) {
        case Constants.ACTIONS.SELF_HEALED:
        case Constants.ACTIONS.COMPLETELY_SELF_HEALED:
            return (
                <p className={ 'action-type' }>
                    { selfHealedFlag && <CheckCircle className={ 'self-healed' } /> }&nbsp;{ Constants.ACTIONS.SELF_HEALED }
                </p>
            );
        case Constants.ACTIONS.AUTO_CREATED:
            return <p className={ 'action-type auto-created' }>{ value }</p>;
        default:
            return '';
    }
};

/**
 * Get incident number options
 *
 * @param value
 * @param tableMeta
 * @param currentAgentIndex
 * @param documentIdIndex
 * @returns {null|*}
 */
export const getIncidentNumberOption = (value, tableMeta, currentAgentIndex, documentIdIndex) => {
    if (!isEmpty(value) && !isUndefined(value)) {
        return (
            <a className={ 'incident-no-link' }
                onClick={ (event) => openServiceNow(event, value) } href={ `#${ value }` }>
                { value }
            </a>
        );
    } else {
        const loggedInUser = getUsername();
        const currentAgent = tableMeta.rowData[ currentAgentIndex ];
        const documentId = tableMeta.rowData[ documentIdIndex ];
        if (!isNull(currentAgent) && !isEmpty(currentAgent) && isEqual(currentAgent, loggedInUser)) {
            return (
                <Button variant="outlined" size="small" color="primary"
                    className={ 'create-incident-btn' }
                    disabled={ !checkUserHasPermission(Constants.COMMON.AGENT_ACTIONS.CREATE_SNOW) }
                    onClick={ () => {
                        const state = store.getState();
                        const { tableFilterList, tableColumns } = state.activeAlertsInfo;
                        // update column options in active alerts
                        updateColumnOptions(tableFilterList, tableColumns);
                        // create incident API call
                        store.dispatch(createIncident(documentId));
                    } }>
                    Create
                </Button>
            );
        } else {
            return '';
        }
    }
};

/**
 * Get Associated Events options
 *
 * @param value
 * @param tableMeta
 * @param dataSource
 * @param alertIdIndex
 * @param alertSourceIndex
 * @param dataSourceUrlIndex
 * @param parentEvent
 * @returns {*}
 */
export const getAssociatedEventsOption = (value, tableMeta, dataSource, alertIdIndex, alertSourceIndex, dataSourceUrlIndex, parentEvent) => {
    // build associated event link
    const associatedEventLink = (
        <a className={ 'associated-event-link' }
            href={ `#${ value }` }
            onClick={ (event) => {
                event.preventDefault();
                // find alert Obj from store
                const alertObj = find(dataSource, (item) => {
                    // check for matching `_id`
                    return isEqual(item._id, tableMeta.rowData[ alertIdIndex ]);
                });
                const events = !isUndefined(alertObj) && !isNull(alertObj.associatedEventsList) ? alertObj.associatedEventsList : [];
                const associatedEvents = !isNull(parentEvent) ? [ parentEvent, ...events ] : events;
                // set associated events list
                store.dispatch(setAssociatedEvents(associatedEvents));
                store.dispatch(toggleAssociatedEventsModal());
            } }>
            { value }
        </a>
    );

    if (tableMeta && isArray(tableMeta.rowData) && tableMeta.rowData.length > 0
        && startsWith(lowerCase(tableMeta.rowData[ alertSourceIndex ]), Constants.COMMON.SYSTEM_SOURCE.DYNATRACE_PRE_FIX)) {
        // check synthetic
        const isSynthetic = endsWith(lowerCase(tableMeta.rowData[ alertSourceIndex ]), Constants.COMMON.SYSTEM_SOURCE.SYNTHETIC_PRE_FIX);
        if (isSynthetic) {
            if (value === 0) {
                // if value is `0`
                return value;
            } else {
                // for `dynatrace synthetic` show only count
                return (
                    <span>
                        { associatedEventLink }
                    </span>
                );
            }
        } else {
            // get dynatrace source link
            const dataSourceUrl = tableMeta.rowData[ dataSourceUrlIndex ];
            return (
                <span>
                    <a className={ 'associated-event-dynatrace-link' } target={ '_blank' } href={ dataSourceUrl }>
                        DT
                    </a>
                </span>
            );
        }
    } else if (tableMeta && isArray(tableMeta.rowData) && tableMeta.rowData.length > 0
        && startsWith(lowerCase(tableMeta.rowData[ alertSourceIndex ]), Constants.COMMON.SYSTEM_SOURCE.SOLARWINDS_PRE_FIX)) {
        // get solarwinds source link
        const dataSourceUrl = tableMeta.rowData[ dataSourceUrlIndex ];
        const swLink = (
            dataSourceUrl !== '' ?
                <a className={ 'associated-event-solarwinds-link' } target={ '_blank' } href={ dataSourceUrl }>
                    SW
                </a> : 'SW'
        );

        if (value > 0) {
            return (
                <span>
                    { swLink }
                    &nbsp;
                    ({ associatedEventLink })
                </span>
            );
        } else {
            return swLink;
        }
    } else if (value > 0) {
        // set open associated events link
        return associatedEventLink;
    } else {
        return value;
    }
};

/**
 * Sort Patter for current agent
 *
 * @param item
 * @param colIndex
 */
export const sortPatternForCurrentAgent = (item, colIndex) => {
    if (isEmpty(item.data[ colIndex ]) || isNull(item.data[ colIndex ])) {
        return Constants.COMMON.UNASSIGNED;
    } else {
        return item.data[ colIndex ];
    }
};

/**
 * Sort Pattern for associated events
 *
 * @param item
 * @param colIndex
 * @param sourceIndex
 * @returns {string}
 */
export const sortPatternForAssociatedEvents = (item, colIndex, sourceIndex) => {
    if (startsWith(lowerCase(item.data[ sourceIndex ]), Constants.COMMON.SYSTEM_SOURCE.DYNATRACE_PRE_FIX)) {
        // dynatrace
        const isSynthetic = endsWith(lowerCase(item.data[ sourceIndex ]), Constants.COMMON.SYSTEM_SOURCE.SYNTHETIC_PRE_FIX);
        if (isSynthetic) {
            return `${ item.data[ colIndex ] }`;
        } else {
            return 'DT';
        }
    } else if (startsWith(lowerCase(item.data[ sourceIndex ]), Constants.COMMON.SYSTEM_SOURCE.SOLARWINDS_PRE_FIX)) {
        return `SW-${ item.data[ colIndex ] }`;
    } else {
        return `${ item.data[ colIndex ] }`;
    }
};

/**
 * Get Sort Order for given column
 *
 * @param columnName
 * @param tableSortState
 * @returns {string}
 */
export const getSortOrderForGivenColumn = (columnName, tableSortState) => {
    // check column name exists in tableSortState
    if (!isUndefined(tableSortState) && isEqual(tableSortState.column, columnName)) {
        return isEqual(tableSortState.mode, Constants.COMMON.ASCENDING_FULL) ? Constants.COMMON.ASCENDING : Constants.COMMON.DESCENDING;
    } else {
        return Constants.COMMON.NONE;
    }
};