import { SET_ASSOCIATED_EVENTS, TOGGLE_ASSOCIATED_EVENTS_MODAL } from '../../redux/constant';
import { isNull, isUndefined } from 'lodash';

/**
 * Handle toggle associated event action
 */
export function toggleAssociatedEventsModal() {
    return {
        type: TOGGLE_ASSOCIATED_EVENTS_MODAL
    };
}

/**
 * Set associated events handler
 *
 * @param data
 */
export function setAssociatedEvents(data) {
    return {
        type: SET_ASSOCIATED_EVENTS,
        data: (isNull(data) || isUndefined(data)) ? [] : data
    };
}
