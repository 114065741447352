import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    fetchClosedAlerts,
    setTableFilters,
    tableColumnChange,
    tableSortChange
} from '../../redux/actions/closedAlertsAction';
import AlertConsole from '../../components/alertConsole/AlertConsole';
import DataTable from '../../components/dataTable/DataTable';
import PropTypes from 'prop-types';
import { toggleFullScreen } from '../../redux/actions/consoleToolbarAction';
import { columns, updateColumnOptions } from './ColumnConfigs';
import { isEqual, isNull } from 'lodash';
import { initializeTimeOnLoad } from '../../redux/actions/timeAction';
import { customSortHandler } from './SortingConfigs';

import './ClosedAlerts.scss';

class ClosedAlerts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            authRefreshTimer: null, // to keep the auto-refresh timer ref
            searchText: '' // the active alert search field text
        };
        // initialize time picker to default value as page type
        this.props.initializeTimeOnLoad(false);
    }

    componentDidMount() {
        const { autoRefresh } = this.props;
        // check auto-refresh status
        if (autoRefresh) {
            this.initializeAutomaticRefresh();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { timeDetails, autoRefresh } = this.props;
        // check for time-range change
        if (!isEqual(prevProps.timeDetails, timeDetails)) {
            // initial data loading also handled by the `TimeDetails` change in the constructor.
            // Also the `TimePicker` changes will be detected here.
            this.refreshHandler();
        } else if (autoRefresh !== prevProps.autoRefresh) {
            // detect auto-refresh change
            if (!autoRefresh && !isNull(prevState.authRefreshTimer)) {
                clearInterval(prevState.authRefreshTimer);
            } else {
                this.initializeAutomaticRefresh();
            }
        }
    }

    componentWillUnmount() {
        // clear timeout interval
        if (!isNull(this.state.authRefreshTimer)) {
            clearInterval(this.state.authRefreshTimer);
        }
    }

    /**
     * Initialize Automatic refresh
     */
    initializeAutomaticRefresh = () => {
        const authRefreshTimer = setInterval(() => this.refreshHandler(), process.env.REACT_APP_AUTO_REFRESH_INTERVAL);
        this.setState({ authRefreshTimer });
    };

    /**
     * Refresh Handler
     */
    refreshHandler = () => {
        const { fetchClosedAlertsHandler } = this.props;
        // do alert fetching
        fetchClosedAlertsHandler();
    };

    render() {
        const { tableData, pending, fullScreen, tableFilterList, tableColumns, tableSort, toggleFullScreenHandler, tableColumnChangeHandler, tableFilterChangeHandler, tableSortChangeHandler } = this.props;
        const { searchText } = this.state;
        const options = {
            searchText: searchText,
            customSort: customSortHandler,
            onColumnSortChange: (changedColumn, direction) => {
                tableSortChangeHandler(changedColumn, direction);
            },
            onColumnViewChange: (changedColumn, action) => {
                tableColumnChangeHandler(changedColumn, action);
            },
            onFilterChange: (changedColumn, filterList) => {
                tableFilterChangeHandler(filterList);
            },
            onSearchChange: (searchValue) => {
                this.setState({ searchText: searchValue });
            }
        };
        // update column options
        updateColumnOptions(tableFilterList, tableColumns, tableSort);
        // render items
        return (
            <AlertConsole
                fullScreen={ fullScreen }
                toggleFullScreen={ toggleFullScreenHandler }
            >
                <DataTable
                    columns={ columns }
                    data={ tableData }
                    options={ options }
                    loading={ pending }
                    subTable={ false }
                    fullScreen={ fullScreen }
                    filterList={ tableFilterList }
                    refresh={ this.refreshHandler }
                />
            </AlertConsole>
        );
    }
}

const mapStateToProps = state => ({
    tableData: state.closedAlertsInfo.tableData,
    tableColumns: state.closedAlertsInfo.tableColumns,
    tableSort: state.closedAlertsInfo.tableSort,
    tableFilterList: state.closedAlertsInfo.tableFilterList,
    pending: state.closedAlertsInfo.pending,
    fullScreen: state.consoleSettings.fullScreen,
    timeDetails: state.timeDetails,
    autoRefresh: state.autoRefreshInfo.status
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        fetchClosedAlertsHandler: fetchClosedAlerts,
        toggleFullScreenHandler: toggleFullScreen,
        initializeTimeOnLoad: initializeTimeOnLoad,
        tableColumnChangeHandler: tableColumnChange,
        tableFilterChangeHandler: setTableFilters,
        tableSortChangeHandler: tableSortChange
    },
    dispatch
);

ClosedAlerts.propTypes = {
    tableData: PropTypes.array.isRequired,
    tableColumns: PropTypes.object.isRequired,
    tableSort: PropTypes.object.isRequired,
    tableFilterList: PropTypes.array.isRequired,
    pending: PropTypes.bool.isRequired,
    fullScreen: PropTypes.bool.isRequired,
    timeDetails: PropTypes.object.isRequired,
    toggleFullScreenHandler: PropTypes.func.isRequired,
    fetchClosedAlertsHandler: PropTypes.func.isRequired,
    autoRefresh: PropTypes.bool,
    initializeTimeOnLoad: PropTypes.func.isRequired,
    tableColumnChangeHandler: PropTypes.func.isRequired,
    tableFilterChangeHandler: PropTypes.func.isRequired,
    tableSortChangeHandler: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ClosedAlerts);
