import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import dayjs from 'dayjs';
import DialogTitle from '@material-ui/core/DialogTitle';
import Constants from '../../utils/constants';
import TabPanel from '../tabPanel/TabPanel';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { getTimeInstance } from '../../utils/timeHelper';

import './timePickerDialog.scss';

const tealColor = '#009ca9';

const materialTheme = createMuiTheme({
    overrides: {
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: tealColor
            }
        },
        MuiPickerDTTabs: {
            tabs: {
                backgroundColor: tealColor
            }
        },
        MuiPickersDay: {
            daySelected: {
                backgroundColor: tealColor
            },
            current: {
                color: tealColor
            }
        },
        MuiPickersModal: {
            dialogAction: {
                color: tealColor
            }
        }
    }
});

const TimePickerDialog = ({ open, close, onSelect, currentTab }) => {
    const [ selectedTab, setSelectedTab ] = React.useState('one');
    const [ startDate, handleStartDateChange ] = React.useState(new Date());
    const [ endDate, handleEndDateChange ] = React.useState(new Date());
    const [ maxDateError, handleMaxDateFlag ] = React.useState(false);
    const tabChange = (event, tab) => {
        setSelectedTab(tab);
    };

    const a11yProps = index => {
        return {
            id: `wrapped-tab-${ index }`,
            'aria-controls': `wrapped-tabpanel-${ index }`
        };
    };

    const formatDate = date => {
        return (
            date.getDate() +
            '/' +
            (date.getMonth() + 1) +
            '/' +
            date.getFullYear() +
            ' ' +
            date.getHours() +
            ':' +
            date.getMinutes()
        );
    };

    const saveClick = () => {
        const time = {
            unit: 'custom',
            start: startDate,
            end: endDate,
            startDateTimeStamp: dayjs()
                .utc(startDate)
                .valueOf(),
            endDateTimestamp: dayjs()
                .utc(endDate)
                .valueOf()
        };
        if (endDate < startDate) {
            handleMaxDateFlag(true);
            return;
        }
        handleMaxDateFlag(false);
        time.name = formatDate(startDate) + ' TO ' + formatDate(endDate);
        onSelect(time);
    };

    const onSelectTime = (ev, time) => {
        onSelect(getTimeInstance(time));
    };

    return (
        <Dialog
            fullWidth={ false }
            maxWidth={ 'sm' }
            open={ open }
            onClose={ close }
            aria-labelledby='max-width-dialog-title'
        >
            <DialogTitle className='max-width-dialog-title'>
                Time Range
            </DialogTitle>
            <DialogContent dividers className={ 'time-picker-content' }>
                <Tabs
                    value={ selectedTab }
                    indicatorColor='primary'
                    textColor='primary'
                    onChange={ tabChange }
                    aria-label='disabled tabs example'
                >
                    <Tab
                        value='one'
                        label='Quick'
                        wrapped
                        { ...a11yProps('one') }
                    />
                    <Tab value='two' label='Date Range' { ...a11yProps('two') } />
                </Tabs>
                <TabPanel value={ selectedTab } index='one'>
                    <Grid container spacing={ 1 }>
                        { Constants.TIMES.map((time, i) => {
                            return (currentTab === 1 && i === 0) ? null :
                            <Grid item xs={ 4 } key={ i }>
                                <Button onClick={ e => onSelectTime(e, time) }>
                                    { time.name }
                                </Button>
                            </Grid>;
                        }) }
                    </Grid>
                </TabPanel>
                <TabPanel value={ selectedTab } index='two'>
                    <Grid container spacing={ 4 }>
                        <MuiPickersUtilsProvider utils={ DateFnsUtils }>
                            <Grid item xs={ 6 }>
                                <MuiThemeProvider theme={ materialTheme }>
                                    <DateTimePicker
                                        variant='inline'
                                        label='From'
                                        value={ startDate }
                                        onChange={ handleStartDateChange }
                                    />
                                </MuiThemeProvider>
                            </Grid>

                            <Grid item xs={ 6 }>
                                <MuiThemeProvider theme={ materialTheme }>
                                    <DateTimePicker
                                        variant='inline'
                                        label='To'
                                        error={ maxDateError }
                                        value={ endDate }
                                        onChange={ handleEndDateChange }
                                    />
                                </MuiThemeProvider>
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </Grid>
                </TabPanel>
            </DialogContent>
            <DialogActions>
                <Button onClick={ close } autoFocus>
                    Close
                </Button>
                { selectedTab === 'two' && <Button
                    className={ 'apply-btn' }
                    onClick={ saveClick }
                >
                    Apply
                </Button> }
            </DialogActions>
        </Dialog>
    );
};

TimePickerDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    currentTab: PropTypes.number.isRequired
};

export default TimePickerDialog;
