import { combineReducers } from 'redux';
import { userInfo } from './userInfo';
import { activeAlertsInfo } from './activeAlertsInfo';
import { consoleSettings } from './consoleSettings';
import { timeDetails } from './timeInfo';
import { associatedEventsInfo } from './associatedEventsInfo';
import { autoRefreshInfo } from './autoRefreshInfo';
import { closedAlertsInfo } from './closedAlertsInfo';
import { displayAlertDetails } from './displayAlertDetailsInfo';

const rootReducer = combineReducers({
    userInfo,
    activeAlertsInfo,
    consoleSettings,
    timeDetails,
    associatedEventsInfo,
    autoRefreshInfo,
    closedAlertsInfo,
    displayAlertDetails
});

export default rootReducer;
