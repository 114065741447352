const ALERT = {
    STATUS: {
        IN_PROGRESS: 'In Progress',
        NEW: 'New',
        OPEN: 'Open',
        CLOSED: 'Closed'
    },
    SEVERITY: {
        WARNING: 'Warning',
        CRITICAL: 'Critical'
    },
};

export default ALERT;