import { TIME_DETAILS } from '../constant';
import Constants from '../../utils/constants';
import dayjs from 'dayjs';

// get start date UTC
const start = 0;
// get end date UTC
const end = dayjs();

const initialState = {
    label: Constants.TIMES[ 0 ].label, // set times `All Alerts` option as default value
    name: Constants.TIMES[ 0 ].name,
    duration: Constants.TIMES[ 0 ].duration,
    unit: Constants.TIMES[ 0 ].unit,
    end: end,
    start: start,
    startDateTimeStamp: start.valueOf(),
    endDateTimestamp: end.valueOf()
};

export function timeDetails(state = initialState, action) {
    switch (action.type) {
        case TIME_DETAILS:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
}
