import axios from 'axios';
import { auth } from './authConfig';

/**
 * AXIOS Global Configurations
 */

// set default base-url for API requests
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;

// set request interceptor
axios.interceptors.request.use(async (request) => {
    // set authorization token for every request through AXIOS
    request.headers[ 'Authorization' ] = `Bearer ${ await auth.getAccessToken() }`;
    return request;
}, error => {
    console.error('request error occurred : ', error);
    return Promise.reject(error);
});

// set response interceptor
axios.interceptors.response.use((response) => {
    return response;
}, error => {
    console.error('response error occurred : ', error);
    return Promise.reject(error);
});