import { TOGGLE_FULL_SCREEN } from '../../redux/constant';

const initialState = {
    fullScreen: false,
};

/**
 * Reducer for console Settings
 *
 * @param state
 * @param action
 */
export function consoleSettings(state = initialState, action) {
    switch (action.type) {
        case TOGGLE_FULL_SCREEN:
            return {
                ...state,
                fullScreen: !state.fullScreen
            };
        default:
            return state;
    }
}
