import React from 'react';
import dayjs from 'dayjs';
import { concat, endsWith, isEqual, padStart, isEmpty, isNull } from 'lodash';
import Constants from './constants';

/**
 * Get time instance details
 *
 * @param timeObj
 * @returns {*}
 */
export const getTimeInstance = (timeObj) => {
    const { unit, duration, label } = timeObj;
    let start, end = dayjs().utc();
    if (!unit) {
        // date-time without `unit`
        start = dayjs().startOf(duration);
    } else if (isEqual(unit, Constants.COMMON.CUSTOM)) {
        // custom `date range` found
        start = timeObj.start;
        end = timeObj.end;
    } else if (isEqual(label, Constants.COMMON.TODAY)) {
        // date-time with `today`
        start = dayjs().startOf('day');
    } else if (isEqual(label, Constants.COMMON.ALL)) {
        // date-time with `all alerts`
        start = 0;
    } else {
        // date-time with `unit`
        start = dayjs().utc().subtract(unit, duration);
    }
    timeObj.start = start;
    timeObj.end = end;
    timeObj.startDateTimeStamp = start.valueOf();
    timeObj.endDateTimestamp = end.valueOf();
    return timeObj;
};

/**
 * Get elapsed human readable format
 *
 * @param startTime
 * @param endTime
 * @param showWarning
 * @returns {*}
 */
export const getElapsedHumanReadableFormat = (startTime, endTime, showWarning) => {
    let result = [];
    let current = (!isEmpty(endTime) && !isNull(endTime)) ? dayjs(endTime) : dayjs();
    const alertTime = dayjs(startTime);
    // check days
    const days = current.diff(alertTime, 'day');
    if (days > 0) {
        result.push(`${ padStart(days, 2, '0') } ${ days > 1 ? 'days' : 'day' }`);
        current = current.subtract(days, 'day');
    }
    // check hours
    const hours = current.diff(alertTime, 'hour');
    if (hours > 0) {
        result.push(`${ padStart(hours, 2, '0') } ${ hours > 1 ? 'hrs' : 'hr' }`);
        current = current.subtract(hours, 'hour');
    }
    // check minutes
    const minutes = current.diff(alertTime, 'minute');
    if (minutes > 0) {
        result.push(`${ padStart(minutes, 2, '0') } ${ minutes > 1 ? 'mins' : 'min' }`);
        current = current.subtract(minutes, 'minute');
    }
    // check seconds
    const seconds = current.diff(alertTime, 'second');
    result.push(`${ padStart(seconds, 2, '0') } ${ seconds > 1 ? 'secs' : 'sec' }`);

    // check displaying item count
    if (result.length < 2) {
        // add starting unit for single unit result
        if (endsWith(result[ 0 ], 'mins') || endsWith(result[ 0 ], 'min')) {
            result = concat('00 hr', result);
        } else if (endsWith(result[ 0 ], 'hrs') || endsWith(result[ 0 ], 'hr')) {
            result = concat('00 day', result);
        }
    } else {
        /**
         * Always show only first 2 digits
         * more than 2 units found as elapsed time, then remove trailing items
         */
        result.splice(2);
    }

    return (
        <p className={ 'elapsed-time ' + (showWarning && (days > 0 || hours > 0 || minutes >= 5) ? 'warning-time' : '') }>
            { result.join(' ') }
        </p>
    );
};