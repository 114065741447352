import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { alertStateAssigneeExists } from '../../redux/actions/activeAlertsAction';

import './AssigneeExistsAlert.scss';

/**
 * Dialog transition
 */
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ ref } { ...props } />;
});

const AssigneeExistsAlert = ({ assigneeExists, alertStateAssigneeExistsToggle }) => {
    /**
     * Close event handler
     */
    const handleClose = () => {
        alertStateAssigneeExistsToggle();
    };

    return (
        <Dialog
            fullWidth={ true }
            maxWidth={ 'sm' }
            className={ 'assignee-exists-alert' }
            open={ assigneeExists }
            TransitionComponent={ Transition }
            keepMounted
            onClose={ handleClose }
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle className={ 'alert-header' }>
                Alert Console
            </DialogTitle>
            <DialogContent dividers>
                <DialogContentText className={ 'alert-title' }>
                    Alert Assignee Exists
                </DialogContentText>
            </DialogContent>
            <DialogActions className={ 'action-panel' }>
                <Button onClick={ handleClose } color="primary" className={ 'btn' }>
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
};

AssigneeExistsAlert.propTypes = {
    assigneeExists: PropTypes.bool.isRequired,
    alertStateAssigneeExistsToggle: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    assigneeExists: state.activeAlertsInfo.assigneeExists
});

const mapDispatchToProps = dispatch => bindActionCreators({
        alertStateAssigneeExistsToggle: alertStateAssigneeExists
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(AssigneeExistsAlert);