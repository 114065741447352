import {
    CLOSED_ALERTS_TABLE_COLUMNS,
    CLOSED_ALERTS_TABLE_FILTERS,
    CLOSED_ALERTS_TABLE_SORT_COLUMN,
    FETCH_CLOSED_ALERTS_ERROR,
    FETCH_CLOSED_ALERTS_PENDING,
    FETCH_CLOSED_ALERTS_SUCCESS
} from '../../redux/constant';
import Constants from '../../utils/constants';

const initialState = {
    pending: false,
    tableData: [],
    error: null,
    tableSort: {
        // the default sort column
        column: 'last_status_timestamp',
        mode: Constants.COMMON.DESCENDING_FULL
    },
    tableFilterList: [],
    tableColumns: {
        'timestamp': true,
        'last_status_timestamp': true,
        'source': false,
        'message': true,
        'severity': false,
        'total_elapsed_time': true,
        'incident': true,
        'incidentstatus': true,
        'actionType': false,
        'associatedEvents': true,
        'agent': true
    }
};

export function closedAlertsInfo(state = initialState, action) {
    switch (action.type) {
        case FETCH_CLOSED_ALERTS_PENDING:
            return {
                ...state,
                pending: true
            };
        case FETCH_CLOSED_ALERTS_SUCCESS:
            return {
                ...state,
                pending: false,
                tableData: action.payload
            };
        case FETCH_CLOSED_ALERTS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            };
        case CLOSED_ALERTS_TABLE_SORT_COLUMN:
            return {
                ...state,
                tableSort: action.payload
            };
        case CLOSED_ALERTS_TABLE_COLUMNS:
            return {
                ...state,
                tableColumns: action.payload
            };
        case CLOSED_ALERTS_TABLE_FILTERS:
            return {
                ...state,
                tableFilterList: action.payload
            };
        default:
            return state;
    }
}
