import { ACCESS_LEVEL, USER_AUTH_STATUS, USER_INFO } from '../constant';

const initialState = {
    authenticated: false,
    userDetails: {},
    accessLevels: []
};

/**
 * Reducer for user info
 *
 * @param state
 * @param action
 */
export function userInfo(state = initialState, action) {
    switch (action.type) {
        case USER_AUTH_STATUS:
            return {
                ...state,
                authenticated: action.authenticated
            };
        case USER_INFO:
            return {
                ...state,
                userDetails: action.payload
            };
        case ACCESS_LEVEL:
            return {
                ...state,
                accessLevels: action.accessLevels
            };
        default:
            return state;
    }
}
