import React from 'react';
import dayjs from 'dayjs';
import { getElapsedHumanReadableFormat } from '../../utils/timeHelper';
import Constants from '../../utils/constants';
import Timelapse from '@material-ui/icons/Timelapse';
import Star from '@material-ui/icons/Star';
import { isArray, isEmpty, isEqual, isNull, isUndefined, omit, includes, join, upperFirst, lowerCase } from 'lodash';
import {
    customFilterDisplayOptions,
    getActionType,
    getAlertSeverityIcon,
    getAssociatedEventsOption,
    getIncidentNumberOption, getSortOrderForGivenColumn,
    getAlertSourceDisplayValue
} from '../../utils/alertConsoleHelper';
import store from '../../store';
import { handleStateAssignment } from '../../redux/actions/activeAlertsAction';
import { setDisplayAlertDetailsData } from '../../redux/actions/displayAlertDetailsAction';
import { checkUserHasPermission, getUsername } from '../../utils/authHelper';
import NsocToolTip from '../../components/common/nsocToolTip/NsocToolTip';

let columns = [];

/**
 * Update Column options
 *
 * @param tableFilterList
 * @param tableColumns
 * @param tableSort
 */
const updateColumnOptions = (tableFilterList, tableColumns, tableSort) => {
    // find and update in column list
    columns.map((item, index) => {
        const filterOptions = tableFilterList[ index ];
        const columnOption = tableColumns[ item.name ];
        // set column options
        if (!isUndefined(columnOption)) {
            // entry found, then update `display` option
            item.options.display = columnOption;
        }
        // set options filterList
        item.options.filterList = filterOptions;
        // set sort option
        const sortDirection = getSortOrderForGivenColumn(item.name, tableSort);
        if (!isEqual(sortDirection, Constants.COMMON.NONE)) {
            // update `sortDirection`
            item.options = {
                ...item.options,
                sortDirection
            };
        } else {
            // remove the `sortDirection` from current option
            item.options = omit(item.options, [ 'sortDirection' ]);
        }
        return item;
    });
};

/**
 * Get current status icon
 *
 * @param status
 * @returns {*}
 */
const getCurrentStatusIcon = (status) => {
    switch (status) {
        case Constants.ALERT.STATUS.IN_PROGRESS:
            return <Timelapse className={ 'alert-status-in-progress-icon' } />;
        case Constants.ALERT.STATUS.NEW:
            return <Star className={ 'alert-status-new-icon' } />;
        default:
            return '';
    }
};

/**
 * Get last status changed
 *
 * @param value
 * @param tableMeta
 * @returns {string}
 */
const getLastStatusChangedTime = (value, tableMeta) => {
    if (tableMeta && isArray(tableMeta.rowData) && tableMeta.rowData.length > 0) {
        switch (tableMeta.rowData[ 5 ]) {
            case Constants.ALERT.STATUS.NEW:
                return '-';
            default:
                return <p className={ 'alert-last-status-changed' }>{ dayjs(value).format(Constants.COMMON.TIME_FORMAT) }</p>;
        }
    } else {
        return '-';
    }
};

/**
 * Get current agent options
 *
 * @param currentAgent
 * @param tableMeta
 */
const getCurrentAgentOptions = (currentAgent, tableMeta) => {
    const loggedInUser = getUsername();
    const alertId = tableMeta.rowData[ 16 ];
    const alertStatus = tableMeta.rowData[ 5 ];
    let agent = currentAgent;
    let actionType = '';

    // check current agent
    if (isEmpty(currentAgent) || isNull(currentAgent)) {
        agent = Constants.COMMON.UNASSIGNED;
    }

    // determine what is the action
    if (isEqual(alertStatus, Constants.ALERT.STATUS.NEW)) {
        actionType = Constants.COMMON.AGENT_ACTIONS.ASSIGN_TO_ME;
    } else if (isEqual(alertStatus, Constants.ALERT.STATUS.IN_PROGRESS)) {
        actionType = Constants.COMMON.AGENT_ACTIONS.REASSIGN_TO_ME;
        // check current user is same as logged in user
        if (isEqual(currentAgent, loggedInUser)) {
            actionType = Constants.COMMON.AGENT_ACTIONS.CLOSE_TASK;
        }
    }

    const actionLink = (
        <a className={ 'agent-action-link' }
            href={ `#${ alertId }` }
            onClick={ (event) => {
                event.preventDefault();
                const state = store.getState();
                const { tableFilterList, tableColumns } = state.activeAlertsInfo;
                // update column options in active alerts
                updateColumnOptions(tableFilterList, tableColumns);
                // make state change API call
                store.dispatch(handleStateAssignment(alertId, alertStatus, currentAgent, loggedInUser));
            } }>
            { actionType }
        </a>
    );

    return (
        <div className={ 'current-agent-option' }>
            <span className={ 'agent-name' }>{ agent }</span>
            { checkUserHasPermission(actionType) ? actionLink : '' }
        </div>
    );
};

/**
 * Get Associated Events
 *
 * @param value
 * @param tableMeta
 * @returns {*}
 */
const getAssociatedEvents = (value, tableMeta) => {
    const state = store.getState();
    // build parent event obj to pass into
    const parentEvent = {
        'datasource': includes(lowerCase(tableMeta.rowData[ 1 ]), Constants.COMMON.EMAIL) ? tableMeta.rowData[ 19 ] : tableMeta.rowData[ 1 ],
        'event-timestamp': tableMeta.rowData[ 0 ],
        'message': tableMeta.rowData[ 4 ],
        'status': tableMeta.rowData[ 18 ]
    };
    return getAssociatedEventsOption(
        value,
        tableMeta,
        state.activeAlertsInfo.tableData,
        16,
        1,
        15,
        parentEvent
    );
};

/**
 * Custom Filter Display
 *
 * @param filterName
 * @param filterKey
 */
const customFilterDisplay = (filterName, filterKey) => {
    return customFilterDisplayOptions(filterName, filterKey, 'activeAlertsInfo');
};

/**
 * Get elapsed time status option
 *
 * @param value
 * @param tableMeta
 */
const getElapsedTimeForStatusOption = (value, tableMeta) => {
    const alertStatus = (tableMeta && isArray(tableMeta.rowData) && tableMeta.rowData.length > 0) ? tableMeta.rowData[ 5 ] : '';
    switch (alertStatus) {
        case Constants.ALERT.STATUS.NEW:
            return '-';
        default:
            return getElapsedHumanReadableFormat(value, null, false);
    }
};

/**
 * Get Elapsed Response Time
 *
 * @param value
 * @param tableMeta
 */
const getElapsedResponseTime = (value, tableMeta) => {
    const alertStatus = (tableMeta && isArray(tableMeta.rowData) && tableMeta.rowData.length > 0) ? tableMeta.rowData[ 5 ] : '';
    switch (alertStatus) {
        case Constants.ALERT.STATUS.NEW:
            return getElapsedHumanReadableFormat(value, null, true);
        default:
            return getElapsedHumanReadableFormat(value, null, false);
    }
};

/**
 * Active Alerts Column Configurations
 */
columns = [
    {
        label: 'Alert Timestamp',
        name: 'timestamp',
        options: {
            filter: false,
            display: true,
            customBodyRender: (value) => {
                return <p className={ 'alert-timestamp' }>{ dayjs(value).format(Constants.COMMON.TIME_FORMAT) }</p>;
            }
        }
    },
    {
        label: 'Alert Source',
        name: 'source',
        options: {
            filter: true,
            display: true,
            filterType: 'custom',
            // TODO - Verify - customFilterList only shows single value in the chips
            customFilterListRender: (values) => {
                if (!isEmpty(values)) {
                    const newValues = values.map(item => {
                        if (includes(item, Constants.COMMON.EMAILPREFIX)) {
                            return upperFirst(Constants.COMMON.EMAIL);
                        }
                        return item;
                    });
                    return join(newValues, ', ');
                }
                return false;
            },
            filterOptions: {
                logic(actionType, filters) {
                    let modifiedActon;
                    includes(actionType, Constants.COMMON.EMAILPREFIX)
                        ? modifiedActon = upperFirst(Constants.COMMON.EMAIL) : modifiedActon = actionType;
                    if (!isEmpty(filters)) {
                        return !includes(filters, modifiedActon);
                    }
                    return false;
                },
                display: customFilterDisplay('Alert Source', 'source')
            },
            customBodyRender: (value, tableMeta) => {
                return getAlertSourceDisplayValue(value, tableMeta, 19);
            }
        }
    },
    {
        label: 'App Id',
        name: 'app.id',
        options: {
            filter: true,
            display: false,
            filterType: 'custom',
            customFilterListRender: (values) => {
                if (!isEmpty(values)) {
                    const newValues = values.map(item => {
                        if (isEmpty(item)) {
                            return Constants.COMMON.BLANK;
                        }
                        return item;
                    });
                    return join(newValues, ', ');
                }
                return false;
            },
            filterOptions: {
                // TODO - Verify - `actionTypes` gives null always
                logic(actionType, filters) {
                    if (!isEmpty(filters)) {
                        return !includes(filters, actionType);
                    }
                    return false;
                },
                display: customFilterDisplay('App Id', 'app.id')
            }
        }
    },
    {
        label: 'App Name',
        name: 'app.name',
        options: {
            filter: true,
            display: false,
            filterType: 'custom',
            customFilterListRender: (values) => {
                if (!isEmpty(values)) {
                    const newValues = values.map(item => {
                        if (isEmpty(item)) {
                            return Constants.COMMON.BLANK;
                        }
                        return item;
                    });
                    return join(newValues, ', ');
                }
                return false;
            },
            filterOptions: {
                // TODO - Verify - `actionTypes` gives null always
                logic(actionType, filters) {
                    if (!isEmpty(filters)) {
                        return !includes(filters, actionType);
                    }
                    return false;
                },
                display: customFilterDisplay('App Name', 'app.name')
            }
        }
    },
    {
        label: 'Alert Message',
        name: 'message',
        options: {
            filter: false,
            display: true,
            customBodyRender: (value, tableMeta) => {
                const eventIndex = tableMeta.rowData[ 20 ],
                    eventId = tableMeta.rowData[ 21 ];
                return (
                    <NsocToolTip value={ value }>
                        <p
                            className={ 'alert-message-cell' }
                            onClick={ () => {
                                store.dispatch(
                                    setDisplayAlertDetailsData(eventIndex, eventId, value)
                                );
                            } }
                        >
                            { value }
                        </p>
                    </NsocToolTip>
                );
            }
        }
    },
    {
        label: 'Current Status',
        name: 'current_status',
        options: {
            filter: true,
            display: true,
            filterType: 'multiselect',
            customBodyRender: (value) => {
                return (
                    <p className={ 'alert-current-status' }>
                        { getCurrentStatusIcon(value) }&nbsp;{ value }
                    </p>
                );
            }
        }
    },
    {
        label: 'Alert Severity',
        name: 'severity',
        options: {
            filter: true,
            display: true,
            filterType: 'multiselect',
            customBodyRender: (value) => {
                return (
                    <p className={ 'alert-severity' }>
                        { getAlertSeverityIcon(value) }&nbsp;{ value }
                    </p>
                );
            }
        }
    },
    {
        label: 'Elapsed Response Time',
        name: 'elapsed_response_time',
        options: {
            filter: false,
            display: true,
            customBodyRender: (value, tableMeta) => {
                return getElapsedResponseTime(value, tableMeta);
            }
        }
    },
    {
        label: 'Last Status Change',
        name: 'last_status_timestamp',
        options: {
            filter: false,
            display: false,
            customBodyRender: (value, tableMeta) => {
                return getLastStatusChangedTime(value, tableMeta);
            }
        }
    },
    {
        label: 'Elapsed Time (Status)',
        name: 'elapsed_time_status',
        options: {
            filter: false,
            display: false,
            customBodyRender: (value, tableMeta) => {
                return getElapsedTimeForStatusOption(value, tableMeta);
            }
        }
    },
    {
        label: 'Incident #',
        name: 'incident',
        options: {
            filter: true,
            display: true,
            filterType: 'custom',
            // TODO - Verify - customFilterList only shows single value in the chips
            customFilterListRender: (values) => {
                if (!isEmpty(values)) {
                    const newValues = values.map(item => {
                        if (isEmpty(item)) {
                            return Constants.COMMON.BLANK;
                        }
                        return item;
                    });
                    return join(newValues, ', ');
                }
                return false;
            },
            filterOptions: {
                // TODO - Verify - `actionTypes` gives null always
                logic(actionType, filters) {
                    if (!isEmpty(filters)) {
                        return !includes(filters, actionType);
                    }
                    return false;
                },
                display: customFilterDisplay('Incident #', 'incident')
            },
            customBodyRender: (value, tableMeta) => {
                return getIncidentNumberOption(value, tableMeta, 12, 16);
            }
        }
    },
    {
        label: 'Incident Status',
        name: 'incidentstatus',
        options: {
            filter: true,
            display: true,
            filterType: 'multiselect',
            customBodyRender: (value) => {
                return (isEmpty(value) || isUndefined(value)) ? '-' : value;
            }
        }
    },
    {
        label: 'Current Agent',
        name: 'agent',
        options: {
            filter: true,
            display: true,
            filterType: 'custom',
            // TODO - Verify - customFilterList only shows single value in the chips
            customFilterListRender: (values) => {
                if (!isEmpty(values)) {
                    const newValues = values.map(item => {
                        if (isEmpty(item)) {
                            return Constants.COMMON.UNASSIGNED;
                        }
                        return item;
                    });
                    return join(newValues, ', ');
                }
                return false;
            },
            filterOptions: {
                logic(actionType, filters) {
                    if (!isEmpty(filters)) {
                        return !includes(filters, actionType);
                    }
                    return false;
                },
                display: customFilterDisplay('Current Agent', 'agent')
            },
            customBodyRender: (value, tableMeta) => {
                return getCurrentAgentOptions(value, tableMeta);
            }
        }
    },
    {
        label: 'Action Type',
        name: 'actionType',
        options: {
            filter: true,
            display: true,
            filterType: 'custom',
            // TODO - Verify - customFilterList only shows single value in the chips
            customFilterListRender: (values) => {
                if (!isEmpty(values)) {
                    const newValues = values.map(item => {
                        if (isEmpty(item)) {
                            return Constants.COMMON.BLANK;
                        }
                        return item;
                    });
                    return join(newValues, ', ');
                }
                return false;
            },
            filterOptions: {
                logic(actionType, filters) {
                    if (!isEmpty(filters)) {
                        return !includes(filters, actionType);
                    }
                    return false;
                },
                display: customFilterDisplay('Action Type', 'actionType')
            },
            customBodyRender: (value, tableMeta) => {
                const selfHealedFlag = tableMeta.rowData[ 17 ];
                return getActionType(value, selfHealedFlag);
            }
        }
    },
    {
        label: 'Associated Events',
        name: 'associatedEvents',
        options: {
            filter: false,
            display: true,
            customBodyRender: (value, tableMeta) => {
                return getAssociatedEvents(value, tableMeta);
            }
        }
    },
    /**
     * ~ NOTE ~
     * Currently the MUI-Datatable is not supporting for access row object in custom render method,
     * So, in-order to resolve this problem, `datasourceurl` & `_id` were added into columns list as hidden values
     * later these will be taken for query and find actual object from `redux-store`
     */
    {
        name: 'datasourceurl',
        options: {
            filter: false,
            display: 'excluded'
        }
    },
    {
        name: '_id',
        options: {
            filter: false,
            display: 'excluded'
        }
    },
    {
        name: 'isCompletelyHealed',
        options: {
            filter: false,
            display: 'excluded'
        }
    },
    {
        name: 'status',
        options: {
            filter: false,
            display: 'excluded'
        }
    },
    {
        name: 'sourceAlias',
        options: {
            filter: false,
            display: 'excluded'
        }
    },
    {
        name: 'eventIndex',
        options: {
            filter: false,
            display: 'excluded'
        }
    },
    {
        name: 'eventId',
        options: {
            filter: false,
            display: 'excluded'
        }
    }
];

export { columns, updateColumnOptions };