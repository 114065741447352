import dayjs from 'dayjs';
import { isEqual } from 'lodash';
import Constants from '../../utils/constants';
import sort from 'fast-sort';
import { sortPatternForAssociatedEvents, sortPatternForCurrentAgent } from '../../utils/alertConsoleHelper';

/**
 * Custom Sort Handler for Active alerts
 */
export const customSortHandler = (data, colIndex, order) => {
    const now = dayjs();
    switch (colIndex) {
        case 5:
            // total elapsed time
            return isEqual(order, Constants.COMMON.ASCENDING) ?
                sort(data).asc(item => now.diff(dayjs(item.data[ colIndex ]))) :
                sort(data).desc(item => now.diff(dayjs(item.data[ colIndex ])));
        case 9:
            // associated events
            return isEqual(order, Constants.COMMON.ASCENDING) ?
                sort(data).asc(item => sortPatternForAssociatedEvents(item, colIndex, 2)) :
                sort(data).desc(item => sortPatternForAssociatedEvents(item, colIndex, 2));
        case 10:
            // last assignee
            return isEqual(order, Constants.COMMON.ASCENDING) ?
                sort(data).asc(item => sortPatternForCurrentAgent(item, colIndex)) :
                sort(data).desc(item => sortPatternForCurrentAgent(item, colIndex));
        default:
            return isEqual(order, Constants.COMMON.ASCENDING) ?
                sort(data).asc(item => item.data[ colIndex ]) :
                sort(data).desc(item => item.data[ colIndex ]);
    }
};