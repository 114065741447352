import React from 'react';
import Constants from '../../utils/constants';

import './Footer.scss';

const Footer = () => {
    return (
        <div className='footer'>
            <a className='footer-link' href={ `${ process.env.REACT_APP_FEEDBACK_URL }` } target="_blank" rel="noopener noreferrer">
                Feedback
            </a>
            <a className='footer-link'
                href={ `mailto:${ process.env.REACT_APP_CONTACT_EMAIL }?subject=${ Constants.FOOTER.SUBJECT }&body=${ Constants.FOOTER.BODY } ` }
                target="_blank" rel="noopener noreferrer">
                Contact Us
            </a>
        </div>
    );
};

export default Footer;
