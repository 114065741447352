import {
    SET_DISPLAY_ALERT_DETAILS,
    TOGGLE_DISPLAY_ALERT_DETAILS_MODAL,
    FETCH_DISPLAY_ALERT_DETAILS_PENDING,
    FETCH_DISPLAY_ALERT_DETAILS_ERROR
} from '../../redux/constant';

const initialState = {
    pending: false,
    errorbool: false,
    showModal: false,
    displayAlertDetail: {}
};

/**
 * Reducer for Associated Events Info
 *
 * @param state
 * @param action
 */
export function displayAlertDetails(state = initialState, action) {
    switch (action.type) {
        case FETCH_DISPLAY_ALERT_DETAILS_PENDING:
            return {
                ...state,
                pending: true,
                error: false,
                errorbool: false
            };
        case FETCH_DISPLAY_ALERT_DETAILS_ERROR:
            return {
                ...state,
                pending: false,
                showModal: true,
                error: action.error,
                errorbool: true

            };
        case TOGGLE_DISPLAY_ALERT_DETAILS_MODAL:
            return {
                ...state,
                showModal: !state.showModal
            };
        case SET_DISPLAY_ALERT_DETAILS:
            return {
                ...state,
                pending: false,
                error: false,
                errorbool: false,
                showModal: true,
                displayAlertDetail: action.payload
            };
        default:
            return state;
    }
}
