import {
    CLOSED_ALERTS_TABLE_COLUMNS,
    CLOSED_ALERTS_TABLE_FILTERS,
    CLOSED_ALERTS_TABLE_SORT_COLUMN,
    FETCH_CLOSED_ALERTS_ERROR,
    FETCH_CLOSED_ALERTS_PENDING,
    FETCH_CLOSED_ALERTS_SUCCESS
} from '../../redux/constant';
import { fetchTableData } from './alertConsoleActions';
import Constants from '../../utils/constants';
import { isEqual } from 'lodash';

export function fetchPending() {
    return {
        type: FETCH_CLOSED_ALERTS_PENDING
    };
}

export function fetchSuccess(tableData) {
    return {
        type: FETCH_CLOSED_ALERTS_SUCCESS,
        payload: tableData
    };
}

export function fetchError(error) {
    return {
        type: FETCH_CLOSED_ALERTS_ERROR,
        error: error
    };
}

export function tableColumnChange(column, action) {
    return (dispatch, getState) => {
        const tableColumns = getState().closedAlertsInfo.tableColumns;
        // update column state
        tableColumns[ column ] = isEqual(action, Constants.COMMON.ADD);
        // dispatch update
        dispatch(setTableColumns(tableColumns));
    };
}

export function tableSortChange(column, mode) {
    return (dispatch) => {
        // update column sort state
        const tableSort = {
            column,
            mode
        };
        // dispatch update
        dispatch(setTableSortColumn(tableSort));
    };
}

export function setTableSortColumn(tableSort) {
    return {
        type: CLOSED_ALERTS_TABLE_SORT_COLUMN,
        payload: tableSort
    };
}

export function setTableColumns(tableColumnList) {
    return {
        type: CLOSED_ALERTS_TABLE_COLUMNS,
        payload: tableColumnList
    };
}

export function setTableFilters(tableFilterList) {
    return {
        type: CLOSED_ALERTS_TABLE_FILTERS,
        payload: tableFilterList
    };
}

/**
 * Fetch Closed Alerts Data
 *
 * @returns {Function}
 */
export function fetchClosedAlerts() {
    return fetchTableData(Constants.ALERT.STATUS.CLOSED, fetchPending, fetchSuccess, fetchError);
}
