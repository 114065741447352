import dayjs from 'dayjs';
import { isEqual, isNull, isUndefined } from 'lodash';
import Constants from '../../utils/constants';
import sort from 'fast-sort';
import { sortPatternForAssociatedEvents, sortPatternForCurrentAgent } from '../../utils/alertConsoleHelper';

/**
 * Sort Pattern for Last Status Change
 *
 * @param item
 * @param colIndex
 * @returns {number}
 */
const sortPatternForLastStatusChange = (item, colIndex) => {
    if (isEqual(item.data[ 3 ], Constants.ALERT.STATUS.NEW)) {
        return 0;
    } else {
        return dayjs(item.data[ colIndex ]).unix();
    }
};

/**
 * Sort Pattern for elapsed time status
 *
 * @param now
 * @param item
 * @param colIndex
 * @returns {*|number|number}
 */
const sortPatternForElapsedTimeStatus = (now, item, colIndex) => {
    if (isUndefined(item.data[ 3 ]) || isNull(item.data[ 3 ]) || isEqual(item.data[ 3 ], Constants.ALERT.STATUS.NEW)) {
        return 0;
    } else {
        return now.diff(dayjs(item.data[ colIndex ]));
    }
};

/**
 * Custom Sort Handler for Active alerts
 */
export const customSortHandler = (data, colIndex, order) => {
    const now = dayjs();
    switch (colIndex) {
        case 5:
            // elapsed response time
            return isEqual(order, Constants.COMMON.ASCENDING) ?
                sort(data).asc(item => now.diff(dayjs(item.data[ colIndex ]))) :
                sort(data).desc(item => now.diff(dayjs(item.data[ colIndex ])));
        case 6:
            // last status change
            return isEqual(order, Constants.COMMON.ASCENDING) ?
                sort(data).asc(item => sortPatternForLastStatusChange(item, colIndex)) :
                sort(data).desc(item => sortPatternForLastStatusChange(item, colIndex));
        case 7:
            // elapsed time (status)
            return isEqual(order, Constants.COMMON.ASCENDING) ?
                sort(data).asc(item => sortPatternForElapsedTimeStatus(now, item, colIndex)) :
                sort(data).desc(item => sortPatternForElapsedTimeStatus(now, item, colIndex));
        case 10:
            // current agent
            return isEqual(order, Constants.COMMON.ASCENDING) ?
                sort(data).asc(item => sortPatternForCurrentAgent(item, colIndex)) :
                sort(data).desc(item => sortPatternForCurrentAgent(item, colIndex));
        case 12:
            // associated events
            return isEqual(order, Constants.COMMON.ASCENDING) ?
                sort(data).asc(item => sortPatternForAssociatedEvents(item, colIndex, 1)) :
                sort(data).desc(item => sortPatternForAssociatedEvents(item, colIndex, 1));
        default:
            return isEqual(order, Constants.COMMON.ASCENDING) ?
                sort(data).asc(item => item.data[ colIndex ]) :
                sort(data).desc(item => item.data[ colIndex ]);
    }
};