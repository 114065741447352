import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toggleAssociatedEventsModal } from '../../redux/actions/associatedEventsAction';
import DataTable from '../dataTable/DataTable';
import columns from './ColumnConfigs';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import './AssociatedEvents.scss';

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[ 500 ],
    },
});

/**
 * Dialog title section
 */
const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={ classes.root }>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={ classes.closeButton } onClick={ onClose }>
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

/**
 * Dialog content section
 */
const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const AssociatedEvents = ({ openStatus, tableData, associatedEventsModalHandler }) => {
    /**
     * Close event handler
     */
    const handleClose = () => {
        associatedEventsModalHandler(false);
    };
    const options = {
        print: false,
        download: false,
        filter: false,
        search: false,
        viewColumns: false,
        customToolbar: null,
        customRowRender: (data, dataIndex, rowIndex) => {
            return (
                <TableRow key={ dataIndex } className={ `custom-table-row ${ dataIndex === 0 ? 'parent-data-row' : '' }` }>
                    {data.map((item, index) => (
                        <TableCell
                            key={ `${ dataIndex }-${ index }` }
                            className={ 'custom-table-cell' }>
                            {item}
                        </TableCell>
                    ))}
                </TableRow>
            );
        }
    };

    return (
        <Dialog
            onClose={ handleClose }
            open={ openStatus }
            fullWidth={ true }
            maxWidth={ 'md' }>
            <DialogTitle onClose={ handleClose }>
                Associated Events
            </DialogTitle>
            <DialogContent dividers>
                <DataTable
                    columns={ columns }
                    options={ options }
                    data={ tableData }
                    fullScreen={ false }
                    loading={ false }
                    subTable={ true }
                    refresh={ null }
                    toggleFullScreen={ null }
                />
            </DialogContent>
        </Dialog>
    );
};

AssociatedEvents.propTypes = {
    openStatus: PropTypes.bool.isRequired,
    tableData: PropTypes.array.isRequired,
    associatedEventsModalHandler: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    openStatus: state.associatedEventsInfo.showModal,
    tableData: state.associatedEventsInfo.tableData
});

const mapDispatchToProps = dispatch => bindActionCreators({
        associatedEventsModalHandler: toggleAssociatedEventsModal
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(AssociatedEvents);