import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { displayAlertDetailsModalClose } from '../../redux/actions/displayAlertDetailsAction';
import dayjs from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
import Constants from '../../utils/constants';
import { forEach, upperFirst, includes } from 'lodash';
import './DisplayAlertDetails.scss';
import { Grid, Divider } from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';

dayjs.extend(AdvancedFormat);

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[ 500 ]
    },
    row: {
        padding: theme.spacing(1)
    },
    bold: {
        fontWeight: '700',
        fontFamily: 'Open Sans',
        fontSize: '13px',
        paddingLeft: '7px'
    },
    text: {
        fontFamily: 'Open Sans',
        fontSize: '13px',
        wordBreak: 'break-all',
        paddingRight: '10px'
    },
    title: {
        fontSize: '16px',
        fontWeight: '700',
        fontFamily: 'Open Sans',
        color: '#009CA9'
    },
    copy: {
        paddingTop: '10px'
    }
});

/**
 * Dialog title section
 */
const DialogTitle = withStyles(styles)(props => {
    const { children, classes, onClose } = props;
    return (
        <MuiDialogTitle disableTypography className={ classes.root }>
            <Typography variant='h6' className={ classes.title }>
                { children }
            </Typography>
            { onClose ? (
                <IconButton
                    aria-label='close'
                    className={ classes.closeButton }
                    onClick={ onClose }
                >
                    <CloseIcon />
                </IconButton>
            ) : null }
        </MuiDialogTitle>
    );
});

const DisplayRow = withStyles(styles)(props => {
    const { row, classes, toggleSnackbar, copyFlag } = props;
    return (
        <React.Fragment>
            <Grid
                container
                key={ row.name }
                item={ true }
                xs={ 12 }
                className={ classes.row }
            >
                <Grid xs={ 4 } item={ true } className={ classes.bold }>
                    { row.name }
                </Grid>
                <Grid xs={ 8 } item={ true } className={ classes.text }>
                    { copyFlag ? (
                        <>
                            <span dangerouslySetInnerHTML={ { __html: row.data } } />
                            <Grid xs={ 8 } item={ true } className={ classes.copy }>
                                <CopyToClipboard text={ row.data }>
                                    <Button
                                        variant='outlined'
                                        size='small'
                                        color='primary'
                                        className={ 'create-incident-btn' }
                                        onClick={ () => {
                                            toggleSnackbar(true);
                                        } }
                                    >
                                        Copy
                                    </Button>
                                </CopyToClipboard>
                            </Grid>
                        </>
                    ) : row.data }
                </Grid>
            </Grid>
            <Divider />
        </React.Fragment >
    );
});

/**
 * Dialog content section
 */
const DialogContent = withStyles(theme => ({
    root: {
        padding: theme.spacing(0, 0, 6, 0)
    }
}))(MuiDialogContent);

function createData(name, data) {
    return { name, data };
}

const DisplayAlertDetails = ({
    openStatus,
    error,
    displayAlertDetailModalClose,
    alertDetail
}) => {
    const [ snackbarFlag, toggleSnackbar ] = React.useState(false);
    /**
     * Close event handler
     */
    const handleClose = () => {
        displayAlertDetailModalClose();
    };
    /**
     * Close snackbar handler
     */
    const handleCloseSnackbar = () => {
        toggleSnackbar(false);
    };
    /**
     * Open snackbar handler
     */
    const openSnackbar = () => {
        toggleSnackbar(true);
    };

    const rows = [];

    forEach(alertDetail, (value, key) => {
        includes(key, 'timestamp') || includes(key, 'lastStatusUpdate') ? rows.push(createData(upperFirst(key), dayjs(value).format(Constants.COMMON.ALERT_TIME_FORMAT))) : rows.push(createData(upperFirst(key), value));
    });

    return (
        <React.Fragment>
            <Dialog
                onClose={ handleClose }
                open={ openStatus }
                fullWidth={ true }
                maxWidth={ 'md' }
            >
                <DialogTitle onClose={ handleClose }>
                    Alert Details
                </DialogTitle>
                <DialogContent dividers>
                    { !error &&
                        rows.map(row =>
                            row.name === 'Message' ? (
                                <DisplayRow
                                    key={ row.name }
                                    row={ row }
                                    toggleSnackbar={ openSnackbar }
                                    copyFlag={ true }
                                />
                            ) : (
                                <DisplayRow key={ row.name } row={ row } />
                                )
                        ) }
                    { error && (
                        <Grid xs={ 12 } className={ 'no-rows' }>
                            { Constants.COMMON.DISPLAY_ALERT_ERROR_MESSAGE }
                        </Grid>
                    ) }
                </DialogContent>
            </Dialog>
            <Snackbar
                anchorOrigin={ {
                    vertical: 'bottom',
                    horizontal: 'center'
                } }
                className={ 'auto-refresh-snackbar' }
                open={ snackbarFlag }
                autoHideDuration={ 2000 }
                onClose={ handleCloseSnackbar }
                message={
                    <span id='message-id'>{ Constants.COMMON.COPY_MESSAGE }</span>
                }
            />
        </React.Fragment>
    );
};

DisplayAlertDetails.propTypes = {
    openStatus: PropTypes.bool.isRequired,
    alertDetail: PropTypes.object.isRequired,
    displayAlertDetailModalClose: PropTypes.func.isRequired,
    error: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
    openStatus: state.displayAlertDetails.showModal,
    alertDetail: state.displayAlertDetails.displayAlertDetail,
    error: state.displayAlertDetails.errorbool
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            displayAlertDetailModalClose: displayAlertDetailsModalClose
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DisplayAlertDetails);
