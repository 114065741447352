import {
    SET_DISPLAY_ALERT_DETAILS,
    TOGGLE_DISPLAY_ALERT_DETAILS_MODAL,
    FETCH_DISPLAY_ALERT_DETAILS_PENDING,
    FETCH_DISPLAY_ALERT_DETAILS_ERROR
} from '../../redux/constant';
import { isEmpty, forEach, includes, replace } from 'lodash';
import axios from 'axios';

export function fetchSuccess(alertData) {
    return {
        type: SET_DISPLAY_ALERT_DETAILS,
        payload: alertData
    };
}

export function fetchPending() {
    return {
        type: FETCH_DISPLAY_ALERT_DETAILS_PENDING
    };
}

export function fetchError(error) {
    return {
        type: FETCH_DISPLAY_ALERT_DETAILS_ERROR,
        error: error
    };
}

/**
 * Handle toggle display alerts detail action
 */
export function displayAlertDetailsModalClose() {
    return {
        type: TOGGLE_DISPLAY_ALERT_DETAILS_MODAL
    };
}

const iterate = (obj, previousKey = '', newData = {}) => {

    forEach(obj, (value, key) => {
        if (typeof value === 'object') {
            iterate(value, key, newData);
        } else {
            let concatKey = (previousKey + ' ' + key).trim();
            concatKey = replace(concatKey, '@', '');
            if (!includes(concatKey, '_')) newData[ concatKey ] = value;
        }
    });

    return newData;
};

/**
 * Transform Data
 *
 * @param data
 * @returns {*}
 */
const dataTransformer = (data) => {
    const transformedData = iterate(data);
    return transformedData;
};

/**
 * Set display alerts detail handler
 *
 * @param data
 */
export function setDisplayAlertDetailsData(eventIndex, eventId, alertMessage) {
    if (!isEmpty(eventIndex) && !isEmpty(eventId)) {
        return dispatch => {
            dispatch(fetchPending());
            axios
                .get(`https://api.wiley.com/nsoc/ac/v2/alertdetails/${ eventIndex }/${ eventId }`)
                .then(({ data }) => {
                    if (data.error) {
                        throw data.error;
                    }
                    dispatch(displayAlertDetailsModalClose());
                    const newData = { ...data, alertMessage };
                    dispatch(fetchSuccess(dataTransformer(newData)));
                })
                .catch(err => {
                    dispatch(fetchError(err.response.data));
                });
        };
    }
    return dispatch => { dispatch(fetchError('EventId/ eventIndex is blanl')); };
}
