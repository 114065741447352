import React from 'react';
import dayjs from 'dayjs';
import { getElapsedHumanReadableFormat } from '../../utils/timeHelper';
import { includes, isArray, isEmpty, isEqual, isUndefined, join, omit, upperFirst } from 'lodash';
import {
    customFilterDisplayOptions,
    getActionType,
    getAlertSeverityIcon,
    getAssociatedEventsOption,
    getSortOrderForGivenColumn,
    openServiceNow,
    getAlertSourceDisplayValue
} from '../../utils/alertConsoleHelper';
import { setDisplayAlertDetailsData } from '../../redux/actions/displayAlertDetailsAction';
import store from '../../store';
import Constants from '../../utils/constants';
import NsocToolTip from '../../components/common/nsocToolTip/NsocToolTip';

/**
 * Get incident number options
 *
 * @param value
 * @returns {*}
 */
const getIncidentNumberOption = (value) => {
    if (!isEmpty(value) && !isUndefined(value)) {
        return (
            <a className={ 'incident-no-link' }
                onClick={ (event) => openServiceNow(event, value) } href={ `#${ value }` }>
                { value }
            </a>
        );
    } else {
        return '';
    }
};

/**
 * Get current agent options
 *
 * @param currentAgent
 */
const getCurrentAgentOptions = (currentAgent) => {
    return (
        <div className={ 'current-agent-option' }>
            <span className={ 'agent-name' }>{ currentAgent }</span>
        </div>
    );
};

/**
 * Custom Filter Display
 *
 * @param filterName
 * @param filterKey
 */
const customFilterDisplay = (filterName, filterKey) => {
    return customFilterDisplayOptions(filterName, filterKey, 'closedAlertsInfo');
};

/**
 * Get Associated Events
 *
 * @param value
 * @param tableMeta
 * @returns {*}
 */
const getAssociatedEvents = (value, tableMeta) => {
    const state = store.getState();
    // build parent event obj to pass into
    const parentEvent = {
        'datasource': '',
        'event-timestamp': '',
        'message': '',
        'status': null
    };
    if (tableMeta && isArray(tableMeta.rowData) && tableMeta.rowData.length > 0) {
        parentEvent[ 'datasource' ] = tableMeta.rowData[ 19 ];
        parentEvent[ 'event-timestamp' ] = tableMeta.rowData[ 0 ];
        parentEvent[ 'message' ] = tableMeta.rowData[ 5 ];
        parentEvent[ 'status' ] = tableMeta.rowData[ 16 ];
    }
    return getAssociatedEventsOption(
        value,
        tableMeta,
        state.closedAlertsInfo.tableData,
        14,
        2,
        13,
        parentEvent
    );
};

/**
 * Get Total Elapsed Time Option
 *
 * @param tableMeta
 * @returns {*}
 */
const getTotalElapsedTimeOption = (tableMeta) => {
    let alertTimestamp = '';
    let closedTimestamp = '';
    if (tableMeta && isArray(tableMeta.rowData) && tableMeta.rowData.length > 0) {
        alertTimestamp = tableMeta.rowData[ 0 ];
        closedTimestamp = tableMeta.rowData[ 1 ];
    }
    return getElapsedHumanReadableFormat(alertTimestamp, closedTimestamp, false);
};

const columns = [
    {
        label: 'Alert Timestamp',
        name: 'timestamp',
        options: {
            filter: false,
            display: true,
            customBodyRender: (value) => {
                return <p className={ 'alert-timestamp' }>{ dayjs(value).format(Constants.COMMON.TIME_FORMAT) }</p>;
            }
        }
    },
    {
        label: 'Closed Timestamp',
        name: 'last_status_timestamp',
        options: {
            filter: false,
            display: true,
            customBodyRender: (value) => {
                return <p
                    className={ 'alert-last-status-changed' }>{ dayjs(value).format(Constants.COMMON.TIME_FORMAT) }</p>;
            }
        }
    },
    {
        label: 'Alert Source',
        name: 'source',
        options: {
            filter: true,
            display: true,
            filterType: 'custom',
            // TODO - Verify - customFilterList only shows single value in the chips
            customFilterListRender: (values) => {
                if (!isEmpty(values)) {
                    const newValues = values.map(item => {
                        if (includes(item, Constants.COMMON.EMAILPREFIX)) {
                            return upperFirst(Constants.COMMON.EMAIL);
                        }
                        return item;
                    });
                    return join(newValues, ', ');
                }
                return false;
            },
            filterOptions: {
                logic(actionType, filters) {
                    let modifiedActon;
                    includes(actionType, Constants.COMMON.EMAILPREFIX)
                        ? modifiedActon = upperFirst(Constants.COMMON.EMAIL) : modifiedActon = actionType;
                    if (!isEmpty(filters)) {
                        return !includes(filters, modifiedActon);
                    }
                    return false;
                },
                display: customFilterDisplay('Alert Source', 'source')
            },
            customBodyRender: (value, tableMeta) => {
                return getAlertSourceDisplayValue(value, tableMeta, 19);
            }
        }
    },
    {
        label: 'App Id',
        name: 'app.id',
        options: {
            filter: true,
            display: false,
            filterType: 'custom',
            customFilterListRender: (values) => {
                if (!isEmpty(values)) {
                    const newValues = values.map(item => {
                        if (isEmpty(item)) {
                            return Constants.COMMON.BLANK;
                        }
                        return item;
                    });
                    return join(newValues, ', ');
                }
                return false;
            },
            filterOptions: {
                // TODO - Verify - `actionTypes` gives null always
                logic(actionType, filters) {
                    if (!isEmpty(filters)) {
                        return !includes(filters, actionType);
                    }
                    return false;
                },
                display: customFilterDisplay('App Id', 'app.id')
            }
        }
    },
    {
        label: 'App Name',
        name: 'app.name',
        options: {
            filter: true,
            display: false,
            filterType: 'custom',
            customFilterListRender: (values) => {
                if (!isEmpty(values)) {
                    const newValues = values.map(item => {
                        if (isEmpty(item)) {
                            return Constants.COMMON.BLANK;
                        }
                        return item;
                    });
                    return join(newValues, ', ');
                }
                return false;
            },
            filterOptions: {
                // TODO - Verify - `actionTypes` gives null always
                logic(actionType, filters) {
                    if (!isEmpty(filters)) {
                        return !includes(filters, actionType);
                    }
                    return false;
                },
                display: customFilterDisplay('App Name', 'app.name')
            }
        }
    },
    {
        label: 'Alert Message',
        name: 'message',
        options: {
            filter: false,
            display: true,
            customBodyRender: (value, tableMeta) => {
                const eventIndex = tableMeta.rowData[ 17 ],
                    eventId = tableMeta.rowData[ 18 ];
                return (
                    <NsocToolTip value={ value }>
                        <p
                            className={ 'alert-message-cell' }
                            onClick={ () => {
                                store.dispatch(
                                    setDisplayAlertDetailsData(eventIndex, eventId, value)
                                );
                            } }
                        >
                            { value }
                        </p>
                    </NsocToolTip>
                );
            }
        }
    },
    {
        label: 'Alert Severity',
        name: 'severity',
        options: {
            filter: true,
            display: false,
            filterType: 'multiselect',
            customBodyRender: (value) => {
                return (
                    <p className={ 'alert-severity' }>
                        { getAlertSeverityIcon(value) }&nbsp;{ value }
                    </p>
                );
            }
        }
    },
    {
        label: 'Total Elapsed Time',
        // TODO - Verify - total elapsed time logic
        name: 'closed_elapsed_time',
        options: {
            filter: false,
            display: true,
            customBodyRender: (value, tableMeta) => {
                return getTotalElapsedTimeOption(tableMeta);
            }
        }
    },
    {
        label: 'Incident #',
        name: 'incident',
        options: {
            filter: true,
            display: true,
            filterType: 'custom',
            // TODO - Verify - customFilterList only shows single value in the chips
            customFilterListRender: (values) => {
                if (!isEmpty(values)) {
                    const newValues = values.map(item => {
                        if (isEmpty(item)) {
                            return Constants.COMMON.BLANK;
                        }
                        return item;
                    });
                    return join(newValues, ', ');
                }
                return false;
            },
            filterOptions: {
                // TODO - Verify - `actionTypes` gives null always
                logic(actionType, filters) {
                    if (!isEmpty(filters)) {
                        return !includes(filters, actionType);
                    }
                    return false;
                },
                display: customFilterDisplay('Incident #', 'incident')
            },
            customBodyRender: (value) => {
                return getIncidentNumberOption(value);
            }
        }
    },
    {
        label: 'Incident Status',
        name: 'incidentstatus',
        options: {
            filter: true,
            display: true,
            filterType: 'multiselect',
            customBodyRender: (value) => {
                return (isEmpty(value) || isUndefined(value)) ? '-' : value;
            }
        }
    },
    {
        label: 'Action Type',
        name: 'actionType',
        options: {
            filter: true,
            display: false,
            filterType: 'custom',
            // TODO - Verify - customFilterList only shows single value in the chips
            customFilterListRender: (values) => {
                if (!isEmpty(values)) {
                    const newValues = values.map(item => {
                        if (isEmpty(item)) {
                            return Constants.COMMON.BLANK;
                        }
                        return item;
                    });
                    return join(newValues, ', ');
                }
                return false;
            },
            filterOptions: {
                logic(actionType, filters) {
                    if (!isEmpty(filters)) {
                        return !includes(filters, actionType);
                    }
                    return false;
                },
                display: customFilterDisplay('Action Type', 'actionType')
            },
            customBodyRender: (value, tableMeta) => {
                const selfHealedFlag = tableMeta.rowData[ 15 ];
                return getActionType(value, selfHealedFlag);
            }
        }
    },
    {
        label: 'Associated Events',
        name: 'associatedEvents',
        options: {
            filter: false,
            display: true,
            customBodyRender: (value, tableMeta) => {
                return getAssociatedEvents(value, tableMeta);
            }
        }
    },
    {
        label: 'Last Assignee',
        name: 'agent',
        options: {
            filter: true,
            display: true,
            filterType: 'custom',
            // TODO - Verify - customFilterList only shows single value in the chips
            customFilterListRender: (values) => {
                if (!isEmpty(values)) {
                    const newValues = values.map(item => {
                        if (isEmpty(item)) {
                            return Constants.COMMON.UNASSIGNED;
                        }
                        return item;
                    });
                    return join(newValues, ', ');
                }
                return false;
            },
            filterOptions: {
                logic(actionType, filters) {
                    if (!isEmpty(filters)) {
                        return !includes(filters, actionType);
                    }
                    return false;
                },
                display: customFilterDisplay('Last Assignee', 'agent')
            },
            customBodyRender: (value) => {
                return getCurrentAgentOptions(value);
            }
        }
    },
    /**
     * ~ NOTE ~
     * Currently the MUI-Datatable is not supporting for access row object in custom render method,
     * So, in-order to resolve this problem, `datasourceurl` & `_id` were added into columns list as hidden values
     * later these will be taken for query and find actual object from `redux-store`
     */
    {
        name: 'datasourceurl',
        options: {
            filter: false,
            display: 'excluded'
        }
    },
    {
        name: '_id',
        options: {
            filter: false,
            display: 'excluded'
        }
    },
    {
        name: 'isCompletelyHealed',
        options: {
            filter: false,
            display: 'excluded'
        }
    },
    {
        name: 'status',
        options: {
            filter: false,
            display: 'excluded'
        }
    },
    {
        name: 'eventIndex',
        options: {
            filter: false,
            display: 'excluded'
        }
    },
    {
        name: 'eventId',
        options: {
            filter: false,
            display: 'excluded'
        }
    },
    {
        name: 'sourceAlias',
        options: {
            filter: false,
            display: 'excluded'
        }
    },
];

/**
 * Update Column options
 *
 * @param tableFilterList
 * @param tableColumns
 * @param tableSort
 */
const updateColumnOptions = (tableFilterList, tableColumns, tableSort) => {
    // find and update in column list
    columns.map((item, index) => {
        const filterOptions = tableFilterList[ index ];
        const columnOption = tableColumns[ item.name ];
        // set column options
        if (!isUndefined(columnOption)) {
            // entry found, then update `display` option
            item.options.display = columnOption;
        }
        // set options filterList
        item.options.filterList = filterOptions;
        // set sort option
        const sortDirection = getSortOrderForGivenColumn(item.name, tableSort);
        if (!isEqual(sortDirection, Constants.COMMON.NONE)) {
            // update `sortDirection`
            item.options = {
                ...item.options,
                sortDirection
            };
        } else {
            // remove the `sortDirection` from current option
            item.options = omit(item.options, [ 'sortDirection' ]);
        }
        return item;
    });
};

export { columns, updateColumnOptions };