import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import AssociatedEvents from '../associatedEvents/AssociatedEvents';
import DisplayAlertDetails from '../displayAlertDetails/DisplayAlertDetails';
import Layout from '../layout/Layout';

import './AlertConsole.scss';

class AlertConsole extends Component {
    constructor (props) {
        super(props);
        this.state = {
            currentTab: 0
        };
    }

    componentDidMount() {
        let currentTab = 0;
        // Check Current route url
        switch (this.props.location.pathname) {
            case '/alertConsole':
                currentTab = 0;
                break;
            case '/closedAlerts':
                currentTab = 1;
                break;
            default:
                currentTab = 0;
        }
        this.setState({ currentTab });
        // add window event listener
        document.addEventListener('fullscreenchange', this.fullscreenExitHandler, false);
    }

    /**
     * Full Screen exit handler
     */
    fullscreenExitHandler = () => {
        const { fullScreen, toggleFullScreen } = this.props;
        if (!document.fullscreenElement) {
            // check already in full-screen mode
            if (fullScreen) {
                // handle `ESC` action by user in full-screen mode
                toggleFullScreen();
            }
        }
    };

    /**
     * Handle Tan Event Change
     *
     * @param event
     * @param newVal
     */
    onTabChangeHandler = (event, newVal) => {
        let newRoute = '';
        switch (newVal) {
            case 0:
                newRoute = '/alertConsole';
                break;
            case 1:
                newRoute = '/closedAlerts';
                break;
            default:
                newRoute = '/';
        }
        // change route
        this.props.history.push(newRoute);
    };

    render() {
        const { currentTab } = this.state;
        return (
            <Layout>
                <div className={ 'alert-console' }>
                    <Grid container className={ 'alert-console-tabs-con' }>
                        <Grid item xs={ 10 } md={ 6 } sm={ 5 }>
                            <Tabs className={ 'alert-console-tabs' } value={ currentTab }
                                onChange={ this.onTabChangeHandler }>
                                <Tab className={ 'capitalize-fonts' } label="Active Alerts" />
                                <Tab className={ 'capitalize-fonts' } label="Closed Alerts" />
                            </Tabs>
                        </Grid>
                    </Grid>
                    <div className={ 'alerts-container' }>
                        <Grid container>
                            <Grid item xs={ 12 }>
                                { this.props.children }
                            </Grid>
                        </Grid>
                    </div>
                    {/* associated events modal */ }
                    <AssociatedEvents />
                    {/* display alert modal */ }
                    <DisplayAlertDetails />
                </div>
            </Layout>
        );
    }
}

AlertConsole.propTypes = {
    fullScreen: PropTypes.bool,
    toggleFullScreen: PropTypes.func,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired
};

export default withRouter(AlertConsole);
