import {
    ACTIVE_ALERTS_ASSIGNEE_EXISTS,
    ACTIVE_ALERTS_SET_TABLE_DETAILS,
    ACTIVE_ALERTS_STATE_ERROR,
    ACTIVE_ALERTS_STATE_SUCCESS,
    ACTIVE_ALERTS_TABLE_COLUMNS,
    ACTIVE_ALERTS_TABLE_FILTERS,
    ACTIVE_ALERTS_TABLE_SORT_COLUMN,
    FETCH_ACTIVE_ALERTS_ERROR,
    FETCH_ACTIVE_ALERTS_PENDING,
    FETCH_ACTIVE_ALERTS_SUCCESS
} from '../../redux/constant';
import axios from 'axios';
import Constants from '../../utils/constants';
import { fetchTableData } from './alertConsoleActions';
import { isEmpty, isEqual, isUndefined, remove } from 'lodash';

export function fetchPending() {
    return {
        type: FETCH_ACTIVE_ALERTS_PENDING
    };
}

export function fetchSuccess(tableData) {
    return {
        type: FETCH_ACTIVE_ALERTS_SUCCESS,
        payload: tableData
    };
}

export function setTableDetails(tableData) {
    return {
        type: ACTIVE_ALERTS_SET_TABLE_DETAILS,
        payload: tableData
    };
}

export function fetchError(error) {
    return {
        type: FETCH_ACTIVE_ALERTS_ERROR,
        error: error
    };
}

export function alertStateSuccess(data) {
    return {
        type: ACTIVE_ALERTS_STATE_SUCCESS,
        payload: data
    };
}

export function alertStateError(error) {
    return {
        type: ACTIVE_ALERTS_STATE_ERROR,
        error: error
    };
}

export function alertStateAssigneeExists() {
    return {
        type: ACTIVE_ALERTS_ASSIGNEE_EXISTS
    };
}

export function tableSortChange(column, mode) {
    return (dispatch) => {
        // update column sort state
        const tableSort = {
            column,
            mode
        };
        // dispatch update
        dispatch(setTableSortColumn(tableSort));
    };
}

export function setTableSortColumn(tableSort) {
    return {
        type: ACTIVE_ALERTS_TABLE_SORT_COLUMN,
        payload: tableSort
    };
}

export function tableColumnChange(column, action) {
    return (dispatch, getState) => {
        const tableColumns = getState().activeAlertsInfo.tableColumns;
        // update column state
        tableColumns[ column ] = isEqual(action, Constants.COMMON.ADD);
        // dispatch update
        dispatch(setTableColumns(tableColumns));
    };
}

export function setTableColumns(tableColumnList) {
    return {
        type: ACTIVE_ALERTS_TABLE_COLUMNS,
        payload: tableColumnList
    };
}

export function setTableFilters(tableFilterList) {
    return {
        type: ACTIVE_ALERTS_TABLE_FILTERS,
        payload: tableFilterList
    };
}

/**
 * Update state specific alert details
 *
 * @param alertId
 * @param data
 * @returns {Function}
 */
export function updateAlertStateDetails(alertId, data) {
    return (dispatch, getState) => {
        const tableData = getState().activeAlertsInfo.tableData;
        // update table state
        if (!isEmpty(data) && !isUndefined(data[ 'root-cause' ])) {
            let newTableData = tableData;
            if(isEqual(data.alert.status, Constants.ALERT.STATUS.CLOSED)){
                // `closed` alert found
                remove(newTableData, (item) => {
                    return isEqual(item._id, alertId);
                });
            } else {
                newTableData = tableData.map(item => {
                    if (isEqual(item._id, alertId)) {
                        // alert found, update details
                        item[ 'agent' ] = !isUndefined(data.user[ 0 ]) ? data.user[ 0 ] : '';
                        item[ 'last_status_timestamp' ] = !isUndefined(data.alert.lastStatusUpdate) ? data.alert.lastStatusUpdate : '';
                        item[ 'current_status' ] = !isUndefined(data.alert.status) ? data.alert.status : '';
                        // ~ NOTE : set transformed values
                        // `elapsed_time_status` => `last_status_timestamp`
                        item[ 'elapsed_time_status' ] = item[ 'last_status_timestamp' ];
                    }
                    return item;
                });
            }
            // dispatch setTableDetails
            dispatch(setTableDetails([ ...newTableData ]));
        }
    };
}

/**
 * Update incident specific alert details
 *
 * @param alertId
 * @param data
 * @returns {Function}
 */
export function updateAlertIncidentDetails(alertId, data) {
    return (dispatch, getState) => {
        const tableData = getState().activeAlertsInfo.tableData;
        // update table state
        if (!isEmpty(data) && !isUndefined(data[ 'serviceNowResponse' ])) {
            const newTableData = tableData.map(item => {
                if (isEqual(item._id, alertId)) {
                    // alert found, update details
                    item[ 'incident' ] = !isUndefined(data[ 'serviceNowResponse' ][ 'number' ]) ? data[ 'serviceNowResponse' ][ 'number' ] : '';
                    item[ 'incidentstatus' ] = !isUndefined(data[ 'serviceNowResponse' ][ 'state' ]) ? data[ 'serviceNowResponse' ][ 'state' ] : '';
                }
                return item;
            });
            // dispatch setTableDetails
            dispatch(setTableDetails(newTableData));
        }
    };
}

/**
 * Fetch Active Alerts Data
 *
 * @returns {Function}
 */
export function fetchActiveAlerts() {
    return fetchTableData(Constants.ALERT.STATUS.OPEN, fetchPending, fetchSuccess, fetchError);
}

/**
 * Handle state assignment change
 *
 * @param alertId
 * @param currentStatus
 * @param currentUser
 * @param loggedInUser
 */
export function handleStateAssignment(alertId, currentStatus, currentUser, loggedInUser) {
    return dispatch => {
        // show progress indicator
        dispatch(fetchPending());

        // determine expected state
        let expectedStatus = Constants.ALERT.STATUS.IN_PROGRESS;
        if (isEqual(currentStatus, Constants.ALERT.STATUS.NEW)) {
            expectedStatus = Constants.ALERT.STATUS.NEW;
        } else if (isEqual(currentStatus, Constants.ALERT.STATUS.IN_PROGRESS)) {
            // check logged in user match with current user
            if (isEqual(currentUser, loggedInUser)) {
                expectedStatus = Constants.ALERT.STATUS.CLOSED;
            }
        } else {
            expectedStatus = Constants.ALERT.STATUS.CLOSED;
        }

        // put call to alert state manager
        axios.put('/ac/v2/state', {
            alertId,
            status: expectedStatus,
            loggedInUser
        })
            .then(({ data }) => {
                if (data.error) {
                    throw data.error;
                }
                dispatch(alertStateSuccess(data));
                // update alert state details
                dispatch(updateAlertStateDetails(alertId, JSON.parse(data.data)));
            })
            .catch(err => {
                dispatch(alertStateError(err.response.data));
                // check error status
                if (isEqual(err.response.data.status, Constants.ERROR.CONFLICT) &&
                    isEqual(expectedStatus, Constants.ALERT.STATUS.NEW)) {
                    // conflict error found for `New` status request, show `assignee exists error`
                    dispatch(alertStateAssigneeExists());
                    // make refresh call
                    dispatch(fetchActiveAlerts());
                }
            });
    };
}
