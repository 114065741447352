import { TIME_DETAILS } from '../../redux/constant';
import Constants from '../../utils/constants';
import dayjs from 'dayjs';

export function setTimeDetails(time) {
    return {
        type: TIME_DETAILS,
        payload: time
    };
}

export function updateTimeDetails(time) {
    return dispatch => {
        dispatch(setTimeDetails(time));
    };
}

/**
   * Forced Initialize Time Picker to defaut of 'All Alerts' on load of Active alerts
   * @param flag (true for Active, false for closed)
*/
export function initializeTimeOnLoad(flag) {
    let index, start;
    if (flag) {
        index = 0;
        start = 0;// get start date UTC
    } else {
        index = 15;
        start = dayjs().utc().subtract(Constants.TIMES[ index ].duration, Constants.TIMES[ index ].duration);// get start date UTC
    }

    // get end date UTC
    const end = dayjs();
    const time = {
        label: Constants.TIMES[ index ].label, // set times `All Alerts/Last 24 hours` option as initial value
        name: Constants.TIMES[ index ].name,
        duration: Constants.TIMES[ index ].duration,
        unit: Constants.TIMES[ index ].unit,
        end: end,
        start: start,
        startDateTimeStamp: start.valueOf(),
        endDateTimestamp: end.valueOf()
    };

    return dispatch => {
        dispatch(setTimeDetails(time));
    };
}