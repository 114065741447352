/**
 * Authorization settings
 */
const AUTHORIZATION = {
    PERMISSION : {
        READ: 'read',
        WRITE: 'write'
    },
    ACCESS_GROUPS: {
        WRITE_ACCESS: [
            'AC-NSOC-Team Group'
        ],
        READ_ACCESS: [
            'Everyone'
        ]
    }
};

export default AUTHORIZATION;