import React from 'react';
import MUIDataTable from 'mui-datatables';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { flattenDeep, isEmpty } from 'lodash';
import CustomToolbar from './CustomToolbar';

import './DataTable.scss';

const getMuiTheme = (fullScreen, filterList, subTable) => {
    const theme = createMuiTheme();
    const responsiveStacked = subTable ? {} : {
        // `147px` : is the top section height in full-screen mode
        // `187px` : is the top section height in full-screen mode, when filter items are there
        // `267px` : is the top section height, when filter items are there
        // `227px` : is the top section height (standard)
        maxHeight: `calc(100vh - ${ fullScreen ?
            flattenDeep(filterList).length > 0 ? '187' : '147' :
            flattenDeep(filterList).length > 0 ? '267' : '227' }px)`,
        '&::-webkit-scrollbar': {
            width: '8px',
            height: '8px',
            backgroundColor: '#ddd'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#787878',
            borderRadius: '10px'
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#ddd'
        },
        [ theme.breakpoints.down('sm') ]: {
            overflow: 'auto',
            '&::-webkit-scrollbar': {
                width: '14px !important',
                height: '14px !important',
            },
        }
    };
    theme.overrides = {
        MUIDataTable: {
            root: {
                borderBottom: '1px solid #000000',
                fontSize: '13px',
                fontWeight: 'bold',
                color: '#414245'
            },
            paper: {
                boxShadow: 'none',
            },
            tableRoot: {
                minHeight: '120px'
            },
            responsiveStacked
        },
        MuiTablePagination: {
            toolbar: {
                height: '38px',
                minHeight: '38px'
            }
        },
        MUIDataTableHeadCell: {
            root: {
                borderBottom: '1px solid #000000',
                fontSize: '13px',
                fontWeight: 'bold',
                color: '#414245'
            }
        },
        MUIDataTableBodyCell: {
            root: {
                fontSize: '12px',
                fontWeight: 'normal',
                color: '#414245',
                maxWidth: '300px'
            }
        }
    };
    return theme;
};

/**
 * Know Behaviour :
 * when some filter/sorting/search options are there, those will be reset (because of the  force-render)
 */
const DataTable = ({ columns, options, data, loading, fullScreen, filterList, refresh, subTable }) => {
    // build custom options list
    const localOptions = {
        selectableRows: 'none',
        responsive: 'stacked',
        print: false,
        download: false,
        textLabels: {
            body: {
                noMatch: isEmpty(data) ? '' : 'Sorry we could not find any records',
            },
            toolbar: {
                viewColumns: 'Select Columns'
            }
        },
        customToolbar: () => (
            <CustomToolbar refresh={ refresh }/>
        ),
        ...options
    };
    return (
        <div className={ 'custom-data-table' }>
            <MuiThemeProvider theme={ getMuiTheme(fullScreen, filterList, subTable) }>
                <MUIDataTable
                    data={ data }
                    columns={ columns }
                    options={ localOptions }
                />
            </MuiThemeProvider>
            {
                loading &&
                <div className={ 'loading-con' }>
                    <CircularProgress className={ 'loading-indicator' } size={ 45 }/>
                </div>
            }
        </div>
    );
};

DataTable.propTypes = {
    columns: PropTypes.array,
    options: PropTypes.object,
    data: PropTypes.array,
    filterList: PropTypes.array,
    loading: PropTypes.bool.isRequired,
    fullScreen: PropTypes.bool,
    subTable: PropTypes.bool,
    refresh: PropTypes.func
};

export default DataTable;
