/**
 * All common constants
 */
const COMMON = {
    SYSTEM_SOURCE: {
        DYNATRACE_PRE_FIX: 'dynatrace',
        SYNTHETIC_PRE_FIX: 'synthetic',
        SOLARWINDS_PRE_FIX: 'solarwinds'
    },
    BLANK: '(blank)',
    UNASSIGNED: 'unassigned',
    EMAIL: 'email',
    EMAILPREFIX: 'EM:',
    AGENT_ACTIONS: {
        CLOSE_TASK: 'Close Task',
        ASSIGN_TO_ME: 'Assign to me',
        REASSIGN_TO_ME: 'Reassign to me',
        CREATE_SNOW: 'Create SNOW'
    },
    TODAY: 'Today',
    ALL: 'All Alerts',
    CUSTOM: 'custom',
    DISPLAY_ALERT_ERROR_MESSAGE: 'No data present for display.',
    COPY_MESSAGE: 'Message has been successfully copied.',
    ISO_TIME_FORMAT: 'YYYY-MM-DD[T]HH:mm:ss[Z]',
    ALERT_TIME_FORMAT: 'MMMM Do YYYY, HH:mm:ss',
    TIME_FORMAT: 'MM/DD/YYYY HH:mm',
    ASCENDING: 'asc',
    DESCENDING: 'desc',
    NONE: 'none',
    ASCENDING_FULL: 'ascending',
    DESCENDING_FULL: 'descending',
    ADD: 'add'
};

export default COMMON;