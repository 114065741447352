import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { Router } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import ActiveAlerts from '../../containers/activeAlerts/ActiveAlerts';
import ProblemSummary from '../../containers/problemSummary/ProblemSummary';
import SystemHealth from '../../containers/systemHealth/SystemHealth';
import ClosedAlerts from '../../containers/closedAlerts/ClosedAlerts';
import { ImplicitCallback, SecureRoute, Security } from '@okta/okta-react';
import { auth, history } from '../../utils/config/authConfig';

import './App.scss';

const App = () => {
    return (
        <Router history={ history }>
            <Security auth={ auth }>
                <Grid className="main-layout" container spacing={ 0 }>
                    <Grid className={ 'root-item' } item xs={ 12 }>
                        <Switch>
                            <SecureRoute exact sensitive path="/alertConsole" component={ ActiveAlerts }/>
                            <SecureRoute exact sensitive path="/closedAlerts" component={ ClosedAlerts }/>
                            <SecureRoute exact sensitive path="/problemSummary" component={ ProblemSummary }/>
                            <SecureRoute exact sensitive path="/systemHealth" component={ SystemHealth }/>
                            <Route path='/implicit/callback' component={ ImplicitCallback }/>
                            <Redirect to="/alertConsole"/>
                        </Switch>
                    </Grid>
                </Grid>
            </Security>
        </Router>
    );
};

export default App;