import axios from 'axios';
import dayjs from 'dayjs';
import Constants from '../../utils/constants';
import { getTimeInstance } from '../../utils/timeHelper';

/**
 * Transform Data
 *
 * @param data
 * @returns {*}
 */
const dataTransformer = (data) => {
    return data.map(item => {
        // custom name given (not existing in the payload), reason : unique column identifiers
        // `elapsed_response_time` => `timestamp`
        item[ 'elapsed_response_time' ] = item[ 'timestamp' ];
        // `total_elapsed_time` => `timestamp` (for: closeAlerts)
        item[ 'total_elapsed_time' ] = item[ 'timestamp' ];
        // `elapsed_time_status` => `last_status_timestamp`
        item[ 'elapsed_time_status' ] = item[ 'last_status_timestamp' ];
        // `closed_elapsed_time` calculate diff with `last_status_timestamp` and `timestamp`
        item[ 'closed_elapsed_time' ] = dayjs(item[ 'timestamp' ]).diff(item[ 'last_status_timestamp' ]);
        return item;
    });
};

/**
 * Fetch Table Data Items for given status types
 *
 * @param status
 * @param fetchPending
 * @param fetchSuccess
 * @param fetchError
 * @returns {Function}
 */
export function fetchTableData(status, fetchPending, fetchSuccess, fetchError) {
    return (dispatch, getState) => {
        dispatch(fetchPending());
        // get updated time instance
        const { start, end } = getTimeInstance(getState().timeDetails);
        // transform timestamps into alert service supporting format (ISO-8601)
        const fromTimeISO = dayjs(start).local().utc().format(Constants.COMMON.ISO_TIME_FORMAT);
        const toTimeISO = dayjs(end).local().utc().format(Constants.COMMON.ISO_TIME_FORMAT);
        // fetch data
        axios.get(`/ac/v2/alerts?status=${ status }&fromTime=${ fromTimeISO }&toTime=${ toTimeISO }`)
            .then(({ data }) => {
                if (data.error) {
                    throw data.error;
                }
                dispatch(fetchSuccess(dataTransformer(data)));
            })
            .catch(err => {
                dispatch(fetchError(err.response.data));
            });
    };
}