const TIMES = [
    {
        label: 'All Alerts',
        name: 'All Alerts',
        duration: 'hours',
        unit: 24
    },
    {
        label: 'Today',
        name: 'Today',
        duration: 'hours',
        unit: 24
    },
    {
        label: 'Last 15 minutes',
        name: 'Last 15 minutes',
        duration: 'minutes',
        unit: 15
    },
    {
        label: 'Last 30 days',
        name: 'Last 30 days',
        duration: 'days',
        unit: 30
    },
    {
        label: 'This Week',
        name: 'This Week',
        duration: 'week',
        unit: false
    },
    {
        label: 'Last 30 minutes',
        name: 'Last 30 minutes',
        duration: 'minutes',
        unit: 30
    },
    {
        label: 'Last 60 days',
        name: 'Last 60 days',
        duration: 'days',
        unit: 60
    },
    {
        label: 'This Month',
        name: 'This Month',
        duration: 'month',
        unit: false
    },
    {
        label: 'Last 1 hour',
        name: 'Last 1 hour',
        duration: 'hours',
        unit: 1
    },
    {
        label: 'Last 90 days',
        name: 'Last 90 days',
        duration: 'days',
        unit: 90
    },
    {
        label: 'This year',
        name: 'This year',
        duration: 'year',
        unit: false
    },
    {
        label: 'Last 4 hours',
        name: 'Last 4 hours',
        duration: 'hours',
        unit: 4
    },
    {
        label: 'Last 6 months',
        name: 'Last 6 months',
        duration: 'months',
        unit: 6
    },
    {
        label: 'Last 12 hours',
        name: 'Last 12 hours',
        duration: 'hours',
        unit: 12
    },
    {
        label: 'Last 1 year',
        name: 'Last 1 year',
        duration: 'years',
        unit: 1
    },
    {
        label: 'Last 24 hours',
        name: 'Last 24 hours',
        duration: 'hours',
        unit: 24
    },
    {
        label: 'Last 2 year',
        name: 'Last 2 year',
        duration: 'years',
        unit: 2
    },
    {
        label: 'Last 7 days',
        name: 'Last 7 days',
        duration: 'days',
        unit: 7
    },
    {
        label: 'Last 5 years',
        name: 'Last 5 years',
        duration: 'years',
        unit: 5
    }
];

export default TIMES;
