import React from 'react';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/**
 * The root layout component
 */
const Layout = ({ children, fullScreen }) => {
    return (
        <React.Fragment>
            {/* hide if fullscreen */}
            {!fullScreen && <Header/>}
            {/* render child components */}
            {children}
            {/* hide if fullscreen */}
            {!fullScreen && <Footer/>}
        </React.Fragment>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    fullScreen: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
    fullScreen: state.consoleSettings.fullScreen
});

export default connect(mapStateToProps, null)(Layout);