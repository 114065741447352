import React from 'react';
import dayjs from 'dayjs';
import Constants from '../../utils/constants';
import NsocToolTip from '../common/nsocToolTip/NsocToolTip';

const columns = [
    {
        label: 'Data Source',
        name: 'datasource',
        options: {
            filter: false
        }
    },
    {
        label: 'Alert Timestamp',
        name: 'event-timestamp',
        options: {
            filter: false,
            customBodyRender: (value) => {
                return <p className={ 'alert-timestamp' }>{ dayjs(value).format(Constants.COMMON.TIME_FORMAT) }</p>;
            }
        }
    },
    {
        label: 'Message',
        name: 'message',
        options: {
            filter: false,
            customBodyRender: (value) => {
                return (
                    <NsocToolTip value={ value }>
                        <p className={ 'alert-message-cell' }>{value}</p>
                    </NsocToolTip>
                );
            }
        }
    },
    {
        label: 'Alert Type',
        name: 'status',
        options: {
            filter: false,
            customBodyRender: (value) => {
                return <p>{ value ? value : '-' }</p>;
            }
        }
    },
];

export default columns;