import TIMES from './time';
import COMMON from './common';
import ACTIONS from './actions';
import ALERT from './alert';
import FOOTER from './footer';
import ERROR from './error';
import AUTHORIZATION from './authorization';

export default {
    COMMON,
    TIMES,
    ACTIONS,
    ALERT,
    FOOTER,
    ERROR,
    AUTHORIZATION
};
