import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '25px'
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const classes = useStyles();

    return (
        <Typography
            component='div'
            role='tabpanel'
            hidden={ value !== index }
            id={ `wrapped-tabpanel-${ index }` }
            className={ classes.root }
            aria-labelledby={ `wrapped-tab-${ index }` }
            { ...other }
        >
            <Box div={ 3 }>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

export default TabPanel;
