import store from '../store';
import Constants from './constants';
import { indexOf, intersection, isEmpty } from 'lodash';

/**
 * Get logged in Username
 *
 * @returns {string}
 */
export const getUsername = () => {
    const state = store.getState(); // get current state
    const username = state.userInfo.userDetails.preferred_username;
    return username.indexOf('@') > 0 ? username.substring(0, username.indexOf('@')) : username;
};

/**
 * Get User Permissions
 *
 * @param user
 * @returns {[]}
 */
export const getUserPermissions = (user) => {
    const roles = user.acnocgroupsclaim;
    const userPermissionLevels = [];
    // get allowed read access groups
    const readAccess = intersection(Constants.AUTHORIZATION.ACCESS_GROUPS.READ_ACCESS, roles);
    // get allowed write access groups
    const writeAccess = intersection(Constants.AUTHORIZATION.ACCESS_GROUPS.WRITE_ACCESS, roles);
    // check read access group found or not
    if (!isEmpty(readAccess)) {
        userPermissionLevels.push(Constants.AUTHORIZATION.PERMISSION.READ);
    }
    // check write access group found or not
    if (!isEmpty(writeAccess)) {
        userPermissionLevels.push(Constants.AUTHORIZATION.PERMISSION.WRITE);
    }
    return userPermissionLevels;
};

/**
 * Check User Has Permission for given action type
 *
 * @param actionType
 * @returns {boolean|number}
 */
export const checkUserHasPermission = (actionType) => {
    const state = store.getState(); // get current state
    const accessLevels = state.userInfo.accessLevels;
    switch (actionType) {
        case Constants.COMMON.AGENT_ACTIONS.ASSIGN_TO_ME:
        case Constants.COMMON.AGENT_ACTIONS.REASSIGN_TO_ME:
        case Constants.COMMON.AGENT_ACTIONS.CLOSE_TASK:
        case Constants.COMMON.AGENT_ACTIONS.CREATE_SNOW:
            return indexOf(accessLevels, Constants.AUTHORIZATION.PERMISSION.WRITE) > -1;
        default:
            return false;
    }
};