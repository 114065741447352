import React, { Component } from 'react';
import dayjs from 'dayjs';

import './SystemClock.scss';

class SystemClock extends Component {
    constructor(props) {
        super(props);
        this.state = { date: dayjs() };
    }

    componentDidMount() {
        this.timerID = setInterval(() => this.tick(), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    tick() {
        this.setState({
            date: dayjs()
        });
    }

    render() {
        return <span className={ 'system-clock' }>{ this.state.date.format('MM/DD/YYYY hh:mm:ss A') }</span>;
    }
}

export default SystemClock;
