import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Fullscreen from '@material-ui/icons/Fullscreen';
import FullscreenExit from '@material-ui/icons/FullscreenExit';
import HelpOutlineRounded from '@material-ui/icons/HelpOutlineRounded';
import Refresh from '@material-ui/icons/Refresh';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { toggleFullScreen } from '../../redux/actions/consoleToolbarAction';
import { connect } from 'react-redux';

const CustomToolbar = ({ refresh, fullScreen, toggleFullScreenHandler }) => {
    // handle open help link action
    const openHelpLinkHandler = () => {
        window.open(process.env.REACT_APP_HELP_URL, '_blank');
    };

    /**
     * Handle Full-Screen change
     */
    const fullScreenHandler = () => {
        if (!fullScreen) {
            document.documentElement.requestFullscreen();
        } else {
            document.exitFullscreen();
        }
        toggleFullScreenHandler();
    };

    return (
        <React.Fragment>
            <Tooltip title="Refresh">
                <IconButton aria-label="refresh" onClick={ refresh } className={ 'icon-button' }>
                    <Refresh />
                </IconButton>
            </Tooltip>
            <Tooltip title={ fullScreen ? 'Exit Full Screen' : 'Full Screen' }>
                <IconButton
                    className={ 'icon-button' }
                    aria-label={ fullScreen ? 'exit full screen' : 'full screen' }
                    onClick={ fullScreenHandler }>
                    { fullScreen ? <FullscreenExit /> : <Fullscreen /> }
                </IconButton>
            </Tooltip>
            <Tooltip title="Help">
                <IconButton
                    aria-label="help"
                    className={ 'icon-button' }
                    onClick={ openHelpLinkHandler }>
                    <HelpOutlineRounded />
                </IconButton>
            </Tooltip>
        </React.Fragment>
    );
};

CustomToolbar.propTypes = {
    fullScreen: PropTypes.bool,
    toggleFullScreenHandler: PropTypes.func,
    openHelpLinkHandler: PropTypes.func,
    refresh: PropTypes.func
};

const mapStateToProps = state => ({
    fullScreen: state.consoleSettings.fullScreen
});

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        toggleFullScreenHandler: toggleFullScreen
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(CustomToolbar);