import { AUTO_REFRESH_FLAG } from '../constant';

const initialState = {
    status: false,
};

export function autoRefreshInfo(state = initialState, action) {
    switch (action.type) {
        case AUTO_REFRESH_FLAG:
            return {
                ...state,
                status: !state.status
            };
        default:
            return state;
    }
}